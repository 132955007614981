import React from "react";
import CoursesCard from "./CoursesCard";
import Header from "../common/Header";
import Footer from "../common/Footer";
import BreadCrumb from "../common/BreadCrumb";

function Main() {
  return (
    <div className="main-wrapper">
      <Header />
      <BreadCrumb title="Our Courses" />
      <CoursesCard />
      <Footer />
    </div>
  );
}

export default Main;
