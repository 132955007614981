import React from "react";
import Profile1 from "./Profile1";
import Profile2 from "./Profile2";
import Profile3 from "./Profile3";
import Profile4 from "./Profile4";

function HeadMember() {
  return (
    <>
      <div className="container py-2 my-5">
        <article className="postcard light blue">
          <div className="postcard__img_link">
            <img
              src="assets/images/instructor/profile/head.png"
              className="postcard__img"
              alt="head"
            />
          </div>
          <div className="postcard__text t-dark">
            <h1 className="postcard__title blue">
              <h5>Fareeha Kashif (BCaBA,IBA,QBA) </h5>
            </h1>
            <div
              className="postcard__subtitle bolder"
              style={{ color: "#f9b01e" }}
            >
              Clinical Director
            </div>
            <div className="postcard__bar"></div>
            <div className="postcard__preview-txt">
              Fareeha Kashif is clinical director of Learning Chip. She has
              behavior analyst certification from three American Boards of
              Behavior Analysis.She is a Board-Certified Behavior Analyst
              (BCaBA), a Qualified Behavior Analyst (QBA) as well as
              International Behavior Analyst (IBA). She received her Post
              Graduate Certification in Applied Behavior Analysis from Florida
              Institute of Technology in Melbourne, USA. She has finished her 8
              hours of supervision training in order to provide ongoing
              supervision to RBTs/IBTs/ABATs. She has completed her master’s
              degree in special education. Her two autistic sons are her pride
              and joy, and she has spent the past 19 years assisting those with
              special needs as a parent and 7 years as a professional
            </div>
          </div>
        </article>
        <Profile1 />
        <Profile2 />
        <Profile3 />
        <Profile4 />
      </div>
    </>
  );
}

export default HeadMember;
