import React, { useEffect } from "react";
import Main from "../component/media/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function Media() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Media | Learning Chip</title>
        <meta
          name="description"
          content="Explore media resources from Learning Chip - The first leading ABA therapy and learning center in Sahiwal, Punjab. Discover videos, photos, and other multimedia content showcasing our services, programs, and community engagements."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default Media;
