import React from "react";
import { ImageGallery } from "react-image-grid-gallery";

function InternationalDay() {
  const imagesArray = [
    {
        alt: "Image3's alt text",
        caption: "International Disability Day",
        src: "assets/images/Website Images/International Disability Day/3.jpeg",
      },
    {
        alt: "Image1's alt text",
        caption: "International Disability Day",
        src: "assets/images/Website Images/International Disability Day/1.jpg",
      },
    {
      alt: "Image2's alt text",
      caption: "International Disability Day",
      src: "assets/images/Website Images/International Disability Day/2.jpeg",
    },

    {
      alt: "Image3's alt text",
      caption: "International Disability Day",
      src: "assets/images/Website Images/International Disability Day/4.jpeg",
    },

    {
      alt: "Image3's alt text",
      caption: "International Disability Day",
      src: "assets/images/Website Images/International Disability Day/6.jpeg",
    },
    {
        alt: "Image3's alt text",
        caption: "International Disability Day",
        src: "assets/images/Website Images/International Disability Day/5.jpeg",
      },
  ];
  return (
    <div className=" edu-blog-area edu-section-gap">
      <div className="wrapper">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">International</span>
                <h3 className="title mb-5">Disability Day</h3>
              </div>
              <div
                className="gallery-grid-wrapper mesonry-list overflow-hidden section-title"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <ImageGallery
                  imagesInfoArray={imagesArray}
                  columnWidth={210}
                  gapSize={20}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternationalDay;
