import React from "react";

function FaqQuestions() {
  return (
    <div className="edu-accordion-area eduvibe-faq-page accordion-shape-1 edu-section-gap bg-color-white">
      <div className="wrapper">
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">FaQ Questions</span>
                <h3 className="title">
                  Get All General Answers <br /> From Here
                </h3>
              </div>
            </div>
          </div>

          <div className="tab-content" id="eduTabContent">
            <div
              className="tab-pane fade show active"
              id="edutabone"
              role="tabpanel"
              aria-labelledby="edutabone-tab"
            >
              <div className="row g-5 align-items-center mt--20">
                <div className="col-lg-12">
                  <div className="accordion-style-1">
                    <div className="edu-accordion" id="accordionExample1">
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="headingOne">
                          <button
                            className="edu-accordion-button "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Who can benefit from ABA training courses?{" "}
                          </button>
                        </div>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            Our ABA training courses can be helpful for Parents
                            of Special needs, professionals, caregivers,
                            educators, or anyone interested in learning about
                            behavior analysis.
                          </div>
                        </div>
                      </div>
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="headingTwo">
                          <button
                            className="edu-accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            What is Applied Behavior Analysis (ABA)?{" "}
                          </button>
                        </div>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            Applied Behavior Analysis (ABA) is a scientific
                            discipline that focuses on understanding and
                            improving human behavior. It is based on the
                            principles of behaviorism, which emphasize
                            observable behaviors and the environmental factors
                            that influence them. ABA applies these principles to
                            various areas such as education, healthcare,
                            developmental disabilities, organizational behavior
                            management.
                          </div>
                        </div>
                      </div>
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="headingThree">
                          <button
                            className="edu-accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            What topics are covered in your ABA training
                            courses?{" "}
                          </button>
                        </div>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            We Covers Basic ABA Principles And Techniques
                            Including Behavior Assessments, Intervention
                            Strategies, Data Collection, And Ethical
                            Considerations.
                          </div>
                        </div>
                      </div>
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="headingFour">
                          <button
                            className="edu-accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Are your courses suitable for beginners or
                            experienced practitioners?{" "}
                          </button>
                        </div>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            Our courses cater to individuals with varying levels
                            of experience in ABA, from beginners seeking
                            foundational knowledge to seasoned practitioners
                            looking to deepen their expertise.
                          </div>
                        </div>
                      </div>
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="heading5">
                          <button
                            className="edu-accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse5"
                            aria-expanded="false"
                            aria-controls="collapse5"
                          >
                            How are the courses structured?
                          </button>
                        </div>
                        <div
                          id="collapse5"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading5"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            our Courses are combination of both self-paced and
                            instructor-led.
                          </div>
                        </div>
                      </div>
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="heading6">
                          <button
                            className="edu-accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse6"
                            aria-expanded="false"
                            aria-controls="collapse6"
                          >
                            Do you offer support or assistance during the
                            course?{" "}
                          </button>
                        </div>
                        <div
                          id="collapse6"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading6"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            Yes, our Course instructor will be available at
                            support and discussion forums for the assistance of
                            participants who may have questions or need guidance
                            during the training.
                          </div>
                        </div>
                      </div>
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="heading7">
                          <button
                            className="edu-accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse7"
                            aria-expanded="faLse"
                            aria-controls="collapse7"
                          >
                            What teaching methods and resources are utilized in
                            your courses?{" "}
                          </button>
                        </div>
                        <div
                          id="collapse7"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading7"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            We offer various instructional approaches such as
                            lectures, case studies, interactive activities, or
                            multimedia presentations.
                          </div>
                        </div>
                      </div>
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="heading8">
                          <button
                            className="edu-accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse8"
                            aria-expanded="false"
                            aria-controls="collapse8"
                          >
                            Will I receive a certificate upon completion of the
                            course?
                          </button>
                        </div>
                        <div
                          id="collapse8"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading8"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            Yes once the participants will meet the course
                            completion criteria successfully which includes
                            attendance of live lectures and securing 85% in
                            course quizzes they will be able to get the Course
                            Completion certification
                          </div>
                        </div>
                      </div>
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="heading9">
                          <button
                            className="edu-accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse9"
                            aria-expanded="false"
                            aria-controls="collapse9"
                          >
                            Are your courses recognized by professional
                            organizations or licensing boards?{" "}
                          </button>
                        </div>
                        <div
                          id="collapse9"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading9"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            Our Courses are recognized from professional
                            organizations in the field of behavior analysis such
                            as <br />
                            1. Behavior Analyst Certification Board (BACB)
                            <br /> 2. International Behavior Analysis
                            Organization (IBAO)
                            <br />
                            3. Qualified Applied Behavior Analysis Credentialing
                            Board (QABA)
                          </div>
                        </div>
                      </div>
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="heading10">
                          <button
                            className="edu-accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse10"
                            aria-expanded="false"
                            aria-controls="collapse10"
                          >
                            Can I access course materials after completing the
                            training?{" "}
                          </button>
                        </div>
                        <div
                          id="collapse10"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading10"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            Yes, our participants have ongoing access to course
                            materials even after completing the training.
                          </div>
                        </div>
                      </div>
                      <div className="edu-accordion-item">
                        <div className="edu-accordion-header" id="heading11">
                          <button
                            className="edu-accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse11"
                            aria-expanded="false"
                            aria-controls="collapse11"
                          >
                            What sets your ABA training courses apart from
                            others?
                          </button>
                        </div>
                        <div
                          id="collapse11"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading11"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="edu-accordion-body">
                            Our expert instructors, practical applications,
                            real-world case studies and opportunities for
                            hands-on experience make us distinguishable from
                            others.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqQuestions;
