import React from "react";

function OnSiteServices() {
  return (
    <div className="eduvibe-home-two-blog edu-blog-area edu-section-gap bg-image">
      <div className="wrapper">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">Online</span>
                <h3 className="title">Services</h3>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--30">
            <div
              className="col-lg-3 col-md-6 col-12"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="edu-blog blog-type-2 bg-white radius-small">
                <div className="inner">
                  <div className="thumbnail">
                    <img
                      src="assets/images/onsite services/1.png"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <div className="status-group">
                      <div className="eduvibe-status status-05">
                        <i className="icon-price-tag-3-line"></i> Initial
                        Assessment
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12"
              data-sal-delay="200"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="edu-blog blog-type-2 bg-white radius-small">
                <div className="inner">
                  <div className="thumbnail">
                    <img
                      src="assets/images/onsite services/2.png"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <div className="status-group">
                      <div className="eduvibe-status status-05">
                        <i className="icon-price-tag-3-line"></i> 1 To 1 ABA
                        Therapy
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12"
              data-sal-delay="200"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="edu-blog blog-type-2 bg-white radius-small">
                <div className="inner">
                  <div className="thumbnail">
                    <img
                      src="assets/images/onsite services/3.png"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <div className="status-group">
                      <div className="eduvibe-status status-05">
                        <i className="icon-price-tag-3-line"></i>ABA Trainings
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12"
              data-sal-delay="250"
              data-sal="slide-up"
              data-sal-duration="800"
            >
            <div className="edu-blog blog-type-2 bg-white radius-small">
  <div className="inner">
    <div className="thumbnail">
      <img
        src="assets/images/onsite services/4.png"
        alt="Blog Images"
        className="w-100"
        style={{ maxWidth: "100%", height: "180px" }}
      />
    </div>
    <div className="content">
      <div className="status-group">
        <div className="eduvibe-status status-05">
          <i className="icon-price-tag-3-line"></i>Parents Trainings
        </div>
      </div>
    </div>
  </div>
</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnSiteServices;
