import React, { useEffect } from "react";
import Main from "../component/course-details/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function CourseDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>ABAT Course | Learning Chip</title>
        <meta
          name="description"
          content="Discover the comprehensive ABAT (Applied Behavior Analysis Therapist) course offered by Learning Chip. Learn about the curriculum, prerequisites, duration, and certification options. Enroll today to embark on a rewarding journey towards becoming a certified ABA therapist and making a positive impact in the lives of differently-abled children."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default CourseDetail;
