import React from "react";
import { Link } from "react-router-dom";

function BlogCard() {
  return (
    <div className="edu-elements-area edu-section-gap bg-color-white">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="row g-5">
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-12"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div className="edu-blog blog-type-3 radius-small">
                  <div className="inner">
                    <div className="content">
                      <div className="status-group">
                        <div className="eduvibe-status status-05  w-800">
                          EDUCATION
                        </div>
                      </div>
                      <Link to="/blog-details">
                        <h4 className="title">
                          How to support a Child with Autism at Home
                        </h4>
                      </Link>
                      <div className="blog-card-bottom">
                        <ul className="blog-meta">
                          <li>
                            <i className="icon-calendar-2-line"></i>22 Jan, 2024
                          </li>
                          <li>
                            <i className="icon-user-line"></i>Posted By Fareeha
                            Kashif
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="thumbnail">
                      <Link to="/blog-details">
                        <img
                          src="assets/images/blog/blog-details-01/blog1.jpg"
                          alt="Blog Images"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
