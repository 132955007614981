import React from "react";

function WhyChoose() {
  return (
    <div className="edu-accordion-area eduvibe-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row g-5">
          <div className="col-lg-6 gallery-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div
                  className="thumbnail"
                  data-sal-delay="150"
                  data-sal="fade"
                  data-sal-duration="800"
                >
                  <img
                    className="radius-small w-100"
                    src="assets/images/about/about-02/why3.jpg"
                    alt="Shape Images"
                  />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-6 mt_sm--15"
                style={{ marginTop: "180px" }}
              >
                <div
                  className="thumbnail"
                  data-sal-delay="150"
                  data-sal="fade"
                  data-sal-duration="800"
                >
                  <img
                    className="radius-small w-100"
                    src="assets/images/about/about-02/whychoose2.jpeg"
                    alt="Shape Images"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="accordion-style-1">
              <div
                className="section-title text-start mb--40"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">Why</span>
                <h3 className="title">Choose Us</h3>
                <p className="description">
                  Learning Chip is dedicated to making a difference and offering
                  an innovative environment to assist learners in reaching their
                  goals.
                </p>
              </div>

              <div
                className="edu-accordion edu-accordion-01"
                id="accordionExample1"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div className="edu-accordion-item">
                  <div className="edu-accordion-header" id="headingOne">
                    <button
                      className="edu-accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Personalised Learning Approach{" "}
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="edu-accordion-body">
                      we belive on tailoring interventions and strategies to
                      meet the specific needs and abilities of each individual.
                      By adopting a personalized learning approach, we use
                      Individualized Assessments,and Tailored Intervention
                      Plans,s along with very flexible instructional strategies.
                      Ongoing data collection and analysis are used to monitor
                      the individual's progress towards their goals.
                    </div>
                  </div>
                </div>

                <div className="edu-accordion-item">
                  <div className="edu-accordion-header" id="headingTwo">
                    <button
                      className="edu-accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Highly Qualified and Trained Staff{" "}
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="edu-accordion-body">
                      Learning chip is blessed to have highly qualified and
                      well-organized staff with the strong understanding of the
                      principles and techniques of ABA.They are equipe with
                      sound knowledge of unique challenges faced by kids with
                      ASD,they demonstrate empathy,compassion,patience and
                      flexibility when working with individuals with special
                      needs.They also possess effective communication
                      skills,both verbal and non verbal which makes a positive
                      impact on the lives of our clients and also contribute to
                      their overall well-being and development.
                    </div>
                  </div>
                </div>

                <div className="edu-accordion-item">
                  <div className="edu-accordion-header" id="headingThree">
                    <button
                      className="edu-accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Evidence-Based Practice{" "}
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="edu-accordion-body">
                      ABA emphasizes the use of interventions that are supported
                      by empirical research and have demonstrated effectiveness
                      in producing meaningful behavior change. Our Practitioners
                      at learning Chip continually evaluate and modify
                      interventions based on the latest research findings and
                      individual client needs.
                    </div>
                  </div>
                </div>

                <div className="edu-accordion-item">
                  <div className="edu-accordion-header" id="headingFour">
                    <button
                      className="edu-accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Positive Behavior Support{" "}
                    </button>
                  </div>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="edu-accordion-body">
                      We utilize positive behavior support strategies to promote
                      pro-social behaviors and prevent challenging behaviors.
                      Staff members are trained in proactive approaches to
                      behavior management, emphasizing reinforcement, clear
                      expectations, and de-escalation techniques.
                    </div>
                  </div>
                </div>

                <div className="edu-accordion-item">
                  <div className="edu-accordion-header" id="headingFive">
                    <button
                      className="edu-accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Parental Involvement and Support{" "}
                    </button>
                  </div>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="edu-accordion-body">
                      Parents are actively involved in the therapy process,
                      including goal setting, treatment planning, and progress
                      monitoring. Learning Chip provides resources, training,
                      and support to empower families to advocate for their
                      loved ones and participate in their care.
                    </div>
                  </div>
                </div>

                <div className="edu-accordion-item">
                  <div className="edu-accordion-header" id="headingSix">
                    <button
                      className="edu-accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      Life Skills Training{" "}
                    </button>
                  </div>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="edu-accordion-body">
                      we proudly Provide targeted instruction and practice in
                      essential life skills,by using combination of structured
                      teaching methods, role-playing, modeling, and real-life
                      experiences to facilitate skill acquisition.
                    </div>
                  </div>
                </div>
                <div className="edu-accordion-item">
                  <div className="edu-accordion-header" id="heading7">
                    <button
                      className="edu-accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse7"
                      aria-expanded="false"
                      aria-controls="collapse7"
                    >
                      Transition Support Services{" "}
                    </button>
                  </div>
                  <div
                    id="collapse7"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading7"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="edu-accordion-body">
                      We Offer transition support services to help individuals
                      with ASD and their families to navigate key transitions,
                      such as transitioning from school to post-secondary
                      education, employment, or independent living.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChoose;
