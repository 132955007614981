import React from "react";

function Module3() {
  return (
    <div className="course-tab-content">
      <h6 className="courses-clr">
        Module 3: Core Principles of ABA (ABATC-03)
      </h6>
      <p className="description">
        <span className="text-dark fs-2 fw-bold">Course Description: </span>This
        5-hours Training module consists of 10 units. This module covers Core
        principle of Applied Behavior Analysis.This covers the concepts,
        principles and processes derived from the experimental analysis of
        behavior, and how they are used to change inappropriate behaviors with
        the more appropriate ones. Participants will be able to comprehend and
        use this fundamental behavior change strategies of applied behavior
        analysis.
      </p>
      <div className="edu-accordion-02" id="accordionExample3">
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="headingOne">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Unit-1 Basic Assumptions in Behaviorism and In Behavior
              Modification (ABATC-3.1)
            </button>
          </div>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Behaviorism{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Briefly Define History of Behaviorism{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Basic Assumptions of Behaviorism{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Types of Behaviorism{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Restricted,
                    Define Methodological Behaviorism{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Radical Behaviorism{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Behavior Modification{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading13">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse13"
              aria-expanded="false"
              aria-controls="collapse13"
            >
              Unit-2 Classical and Operant Conditioning (ABATC-3.2)
            </button>
          </div>
          <div
            id="collapse13"
            className="accordion-collapse collapse"
            aria-labelledby="heading13"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Classical
                    Conditioning
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Factors That
                    Impact Conditioning
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Stimulus-
                    Stimulus Paring
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Examples vs.
                    Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Operant
                    Conditioning
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Examples vs.
                    Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define how Consequences
                    effect Learning
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading14">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse14"
              aria-expanded="false"
              aria-controls="collapse14"
            >
              Unit-3 Reinforcement (ABATC-3.3)
            </button>
          </div>
          <div
            id="collapse14"
            className="accordion-collapse collapse"
            aria-labelledby="heading14"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Definition of
                    Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Characteristics
                    of Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Examples & Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Types of Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Positive
                    Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>2 Types of Positive
                    Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Unconditioned Positive
                    Reinforcement (SR+) Vs Conditioned Positive Reinforcement
                    (Sr+)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Negative
                    Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>2 Types of Negative
                    Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Unconditioned Negative
                    Reinforcement (SR-) Vs Conditioned Negative Reinforcement
                    (Sr-)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Automatic
                    Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Rein forcers
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Types of Rein
                    forcers (Unconditioned vs. Conditioned)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading15">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse15"
              aria-expanded="false"
              aria-controls="collapse15"
            >
              Unit-4 Schedules of Reinforcement (ABATC-3.4)
            </button>
          </div>
          <div
            id="collapse15"
            className="accordion-collapse collapse"
            aria-labelledby="heading15"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define schedules of
                    reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide examples of
                    schedules of reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define 2 specific
                    schedules of reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Continuous
                    Reinforcement Schedules
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Discriminate between
                    continuous, extinction, and intermittent schedules
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List 4 Types of
                    Intermittent Schedules of Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Fixed Ratio
                    Schedules and its effects on Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Fixed Interval
                    Schedules and its effects on Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Variable Ratio
                    Schedules and its effects on Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Variable
                    Interval Schedules and its effects on Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading16">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse16"
              aria-expanded="false"
              aria-controls="collapse16"
            >
              Unit-5 Punishment (ABATC-3.5)
            </button>
          </div>
          <div
            id="collapse16"
            className="accordion-collapse collapse"
            aria-labelledby="heading16"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Definition of
                    Punishment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Characteristics
                    of Punishment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Examples & Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Types of Punishment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Positive
                    Punishment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>2 Types of Positive
                    Punishment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Unconditioned Positive
                    Punishment (SP+) Vs Conditioned Positive Punishment (Sp+)
                    Talented
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Negative
                    Punishment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>2 Types of Negative
                    Punishment Disorder
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Unconditioned Negative
                    Punishment (SP-) Vs Conditioned Negative Punishment (Sp-)
                    Impairment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Punishers
                    Disorder
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Types of
                    Punishers (Unconditioned vs. Conditioned)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading17">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse17"
              aria-expanded="false"
              aria-controls="collapse17"
            >
              Unit-6 Define Behavior and 3-Term Contingencies (ABATC-3.6)
            </button>
          </div>
          <div
            id="collapse17"
            className="accordion-collapse collapse"
            aria-labelledby="heading17"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <h6 className="courses-clr">A) OperantBehavior</h6>
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>2 Definition of
                    Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Examples & Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Two Tests to Determine
                    either it,s Behavior or not
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Public Behavior vs
                    Private Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Examples & Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                B) 3-TermContingencies(OperantContingencies)
              </h6>
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define the term, antecedent
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define the term, Consequence{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define 3-Term
                    Contingency
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Types of Consequence sand Their Effects on Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading18">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse18"
              aria-expanded="false"
              aria-controls="collapse18"
            >
              Unit-7 Operant Extinction (ABATC-3.7)
            </button>
          </div>
          <div
            id="collapse18"
            className="accordion-collapse collapse"
            aria-labelledby="heading18"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Definition of Operant
                    Extinction
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Characteristics
                    of Operant Extinction
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define extinction,
                    deprivation, and satiation
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define extinction burst
                    and spontaneous recovery
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Extinction
                    Effects
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Variables
                    affecting Resistance to Extinction
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe Effective use
                    of Extinction
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading19">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse19"
              aria-expanded="false"
              aria-controls="collapse19"
            >
              Unit-8 Discriminative Stimulus (ABATC-3.8)
            </button>
          </div>
          <div
            id="collapse19"
            className="accordion-collapse collapse"
            aria-labelledby="heading19"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Definition of
                    Discriminative Stimulus
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Examples & Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe Availability
                    versus Value
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Four basic types of
                    discriminative stimuli
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State effects of SDs on
                    Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define the Term
                    Stimulus Control
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Examples & Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading20">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse20"
              aria-expanded="false"
              aria-controls="collapse20"
            >
              Unit-9 MotivatingOperation(ABATC-3.9)
            </button>
          </div>
          <div
            id="collapse20"
            className="accordion-collapse collapse"
            aria-labelledby="heading20"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>History of the terms
                    establishing operation (EO) and motivating operation (MO)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Introduction to
                    motivating operations and their effects
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>The basic 4-term
                    operant contingencies
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe Availability
                    versus Value
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define types of
                    motivating operations
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Examples vs.
                    Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State 2 General Effects
                    of Motivating Operations
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Value Altering
                    Effects of Motivating Operations
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Behavior
                    Altering Effects of Motivating Operations
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Classification of
                    motivating operations (conditioned vs. unconditioned MOs)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading21">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse21"
              aria-expanded="false"
              aria-controls="collapse21"
            >
              Unit-10 Terms and definitions of Applied Verbal Behavior
              (AVB)(ABATC-3.10)
            </button>
          </div>
          <div
            id="collapse21"
            className="accordion-collapse collapse"
            aria-labelledby="heading21"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define History and
                    Skinner’s Verbal Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Concept of
                    functional Language
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Elementary Verbal
                    Operant
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Mand, Provide
                    Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Tact, Provide
                    Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Intraverbal,
                    Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Formal
                    Similarity, Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Point to Point
                    Correspondence, Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Duplic Verbal
                    operant, Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Applications of
                    Verbal Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Role of Verbal
                    Behavior in Language Assessment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Role of Verbal
                    Behavior in Language Intervention
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Mand Training
                    and Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Echoic Training
                    and Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Intraverbal
                    Training and Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Module3;
