import React from "react";
import { ImageGallery } from "react-image-grid-gallery";

function MediaPhotos() {
  const imagesArray = [
    {
      alt: "Image1's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/1.jpeg",
    },
    {
      alt: "Image2's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/2.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/3.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/4.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/5.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/6.jpeg",
    },

    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/7.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/8.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/9.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/11.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/10.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/12.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/13.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/14.jpeg",
    },

    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/16.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/17.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Media Image",
      src: "assets/images/gallery/gallery-images/18.jpeg",
    },
  ];

  return (
    <div className="container">
      <div className="row g-5 mt--5 mb-5">
        <div className="col-12">
          <div
            className="gallery-grid-wrapper mesonry-list overflow-hidden section-title"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <ImageGallery
              imagesInfoArray={imagesArray}
              columnWidth={300}
              gapSize={24}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaPhotos;
