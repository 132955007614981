import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import BreadCrumb from "../common/BreadCrumb";
import BlogCard from "./BlogCard";

function Main() {
  return (
    <div className="main-wrapper">
      <Header />
      <BreadCrumb title="Blog" />
      <div className="edu-elements-area edu-section-gap bg-color-white">
        <div className="container">
          <div className="row g-5">
            <BlogCard />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
