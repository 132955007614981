import React from "react";
import { ImageGallery } from "react-image-grid-gallery";

function AutismSeminar() {
  const imagesArray = [
    {
      alt: "Image1's alt text",
      caption: "Autism Awareness Seminar 2017",
      src: "assets/images/Website Images/Autism Awareness Seminar 2017/3.jpg",
    },
    {
      alt: "Image2's alt text",
      caption: "Autism Awareness Seminar 2017",
      src: "assets/images/Website Images/Autism Awareness Seminar 2017/1.jpg",
    },
    {
      alt: "Image3's alt text",
      caption: "Autism Awareness Seminar 2017",
      src: "assets/images/Website Images/Autism Awareness Seminar 2017/2.jpg",
    },
    {
      alt: "Image3's alt text",
      caption: "Autism Awareness Seminar 2017",
      src: "assets/images/Website Images/Autism Awareness Seminar 2017/8.jpg",
    },
    {
      alt: "Image3's alt text",
      caption: "Autism Awareness Seminar 2017",
      src: "assets/images/Website Images/Autism Awareness Seminar 2017/7.jpg",
    },
    {
      alt: "Image3's alt text",
      caption: "Autism Awareness Seminar 2017",
      src: "assets/images/Website Images/Autism Awareness Seminar 2017/6.jpg",
    },
    {
      alt: "Image3's alt text",
      caption: "Autism Awareness Seminar 2017",
      src: "assets/images/Website Images/Autism Awareness Seminar 2017/5.jpg",
    },
    {
      alt: "Image3's alt text",
      caption: "Autism Awareness Seminar 2017",
      src: "assets/images/Website Images/Autism Awareness Seminar 2017/4.jpg",
    },
    {
        alt: "Image3's alt text",
        caption: "Autism Awareness Seminar 2017",
        src: "assets/images/Website Images/Autism Awareness Seminar 2017/pic 66.jpg",
      },
    
  ];
  return (
    <div className=" edu-blog-area edu-section-gap">
      <div className="wrapper">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">Autism Awareness</span>
                <h3 className="title mb-5">Seminar 2017</h3>
              </div>
              <div
                className="gallery-grid-wrapper mesonry-list overflow-hidden section-title"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <ImageGallery
                  imagesInfoArray={imagesArray}
                  columnWidth={210}
                  gapSize={20}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutismSeminar;
