import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import BreadCrumb from "../common/BreadCrumb";
import HeadMember from "./HeadMember";

function Main() {
  return (
    <div div className="main-wrapper">
      <Header />
      <BreadCrumb title="Our Team" />
      <HeadMember/>
      <Footer />
    </div>
  );
}

export default Main;
