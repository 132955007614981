import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Hero from "./Hero";
import WelcomeChip from "./WelcomeChip";
import Cards from "./Cards";
import WhyChoose from "./WhyChoose";
import OurServices from "./OurServices";
import OurCourses from "./OurCourses";
import Logos from "./Logos";

function Main() {
  return (
    <div div className="main-wrapper">
      <Header />
      <Hero />
      <WelcomeChip />
      <Cards />
      <WhyChoose />
      <OurServices />
      <OurCourses />
      <Logos />
      <Footer />
    </div>
  );
}

export default Main;
