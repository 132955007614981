import React, { useEffect } from "react";
import Main from "../component/services/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Services | Learning Chip</title>
        <meta
          name="description"
          content="Explore the services offered by Learning Chip - The first leading ABA therapy and learning center in Sahiwal, Punjab. Learn about our ABA therapy programs, educational support services, and resources designed to help differently-abled children thrive and reach their full potential."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default Services;
