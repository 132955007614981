import React from "react";
import { Link } from "react-router-dom";

function WelcomeChip() {
  return (
    <div className="home-three-about edu-about-area about-style-4 bg-color-white edu-section-gapTop">
      <div className="container eduvibe-animated-shape">
        <div className="row g-lg-5 g-md-5 g-sm-5">
          <div className="col-lg-12 col-xl-6">
            <div className="gallery-wrapper">
              <img
                className="image-1"
                src="assets/images/about/about-03/welcome.jpg"
                alt="About Images"
              />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6">
            <div className="inner mt_mobile--40">
              <div
                className="section-title text-start"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">Welcome</span>
                <h3 className="title">To Learning Chip</h3>
              </div>
              <div className="feature-style-6 mt--40">
                <div
                  className="edu-feature-list color-variation-1"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <div className="content">
                    <p className="description">
                      At our clinic, we provide Applied Behavior Analysis (ABA)
                      therapy services for individuals with autism spectrum
                      disorder (ASD) and other neurodevelopmental disorders to
                      bring about significant and positive change. Our expert
                      practitioners use a multi-step approach to help children
                      overcome maladaptive behaviors to achieve their
                      developmental milestones.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="read-more-btn"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <Link className="edu-btn mb-5 " to="/about-us">
                  About Us <i className="icon-arrow-right-line-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1 airoplane-img">
            <img src="assets/images/about/about-02/airoplane.png" alt="Shape Thumb" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeChip;
