import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import BreadCrumb from '../common/BreadCrumb'
import CourseDetails from './CourseDetails'

function Main() {
  return (
    <div className='main-wrapper'>
      <Header/>
      <BreadCrumb title="ABAT Course"/>
      <CourseDetails/>
      <Footer/>
    </div>
  )
}

export default Main
