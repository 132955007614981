import React from "react";
import { Link } from "react-router-dom";

function Team() {
  return (
    <div className="edu-elements-area edu-section-gap bg-color-white">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-12">
            <div
              className="section-title text-center"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <span className="pre-title">Introduce</span>
              <h3 className="title">Our Team</h3>
            </div>
          </div>
        </div>
        <div className="row row--20">
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="edu-instructor-grid edu-instructor-1 edu-instructor-1">
              <div className="edu-instructor">
                <div className="inner">
                  <div className="thumbnail">
                    <a href="instructor-profile.html" className="mt-2">
                      <img
                        src="assets/images/instructor/instructor-02/head.png"
                        alt="team images"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="edu-instructor-info">
                <h5 className="title">Fareeha Kashif</h5>
                <span className="desc" style={{ color: "#f9b01e" }}>
                  Clinical Director
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45"
            data-sal-delay="200"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="edu-instructor-grid edu-instructor-1">
              <div className="edu-instructor">
                <div className="inner">
                  <div className="thumbnail">
                    <a href="instructor-profile.html">
                      <img
                        src="assets/images/instructor/instructor-02/profile4.jpeg"
                        alt="team images"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="edu-instructor-info">
                <h5 className="title">Hafiza Malaika Kashif</h5>
                <span className="desc" style={{ color: "#f9b01e" }}>
                  Registered Behavior Technician
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45"
            data-sal-delay="500"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="edu-instructor-grid edu-instructor-1">
              <div className="edu-instructor">
                <div className="inner">
                  <div className="thumbnail">
                    <a href="instructor-profile.html">
                      <img
                        src="assets/images/instructor/profile1.jpeg"
                        alt="team images"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="edu-instructor-info">
                <h5 className="title">Humaira Shaheen</h5>
                <span className="desc" style={{ color: "#f9b01e" }}>
                  Special Educationist/Educational Psychologist
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45"
            data-sal-delay="500"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="edu-instructor-grid edu-instructor-1">
              <div className="edu-instructor">
                <div className="inner">
                  <div className="thumbnail">
                    <a href="instructor-profile.html">
                      <img
                        src="assets/images/instructor/instructor-02/profile2.png"
                        alt="team images"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="edu-instructor-info">
                <h5 className="title">Amna Qayyum</h5>
                <span className="desc" style={{ color: "#f9b01e" }}>
                  ABA Therapist
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45"
            data-sal-delay="500"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="edu-instructor-grid edu-instructor-1">
              <div className="edu-instructor">
                <div className="inner">
                  <div className="thumbnail">
                    <a href="instructor-profile.html">
                      <img
                        src="assets/images/instructor/instructor-02/profile3.jpeg"
                        alt="team images"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="edu-instructor-info">
                <h5 className="title">Kiran Shanaz</h5>
                <span className="desc" style={{ color: "#f9b01e" }}>
                  Art Therapist/ ABA Therapist
                </span>
              </div>
            </div>
          </div>
          <div
            className="read-more-btn text-center mt-5"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <Link className="edu-btn" to="/team">
              Explore more<i className="icon-arrow-right-line-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
