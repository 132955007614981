import React from "react";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div
      id="about-us"
      className="edu-about-area about-style-1 edu-section-gap bg-color-white"
    >
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6">
            <div className="about-image-gallery">
              <img
                className="image-1"
                src="assets/images/about/about-02/why.jpeg"
                alt="About Main Thumb"
              />
              <div className="image-2">
                <img
                  src="assets/images/about/about-02/mom.jpg"
                  alt="About Parallax Thumb"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner">
              <div
                className="section-title"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">Our</span>
                <h3 className="title">Philosophy</h3>
              </div>
              <p
                className="description"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                Founded in 2017, Learning Chip is the first leading ABA therapy
                and learning center located in Sahiwal a small town of Punjab
                with very limited health and education resources, for
                differently abled kids. We are dedicated to creating an
                inclusive and supportive environment where students of all
                abilities can thrive and reach their full potential.
              </p>
              <div className="about-feature-list">
                <div
                  className="our-feature"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <div className="icon">
                    <i className="icon-Hand---Book"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title">Our Mission</h6>
                    <p className="feature-description">
                      Is to Provide an Affordable and Exceptional Educational
                      Experience Tailored to Meet the Unique Needs of
                      Individuals Facing Developmental Challenges by Offering
                      Them the Tools and Opportunities to Succeed Academically,
                      Socially, And Emotionally. Along with Educating Their
                      Families with The Help of Experts and Therapist
                    </p>
                  </div>
                </div>

                <div
                  className="our-feature"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <div className="icon">
                    <i className="icon-Campus"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title">Our Vision</h6>
                    <p className="feature-description">
                      We believe in the power of individualized education, where
                      every student’s ability and strength are recognized and
                      fostered. Our team of highly trained and passionate
                      educators is committed to follow personalized learning
                      plans developed by behavior analysts that address the
                      specific needs of each student.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="read-more-btn"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <Link className="edu-btn" to="/event">
                  Know About Us<i className="icon-arrow-right-line-right"></i>
                </Link>
              </div>
              <div className="shape shape-6 about-parallax-2 d-xl-block d-none airoplane">
                <img
                  src="assets/images/about/about-02/airoplane.png"
                  alt="Shape Thumb"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
