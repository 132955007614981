import React, { useEffect } from "react";
import Main from "../component/blog/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Blogs | Learning Chip</title>
        <meta
          name="description"
          content="Explore insightful articles and resources on ABA therapy, educational support, and developmental challenges on the Learning Chip blog. Stay updated with the latest news, tips, and strategies to support differently-abled children and their families."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default Blog;
