import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";

function Logos() {
  return (
    <div className=" edu-testimonial-area  bg-image">
      <div className="container eduvibe-animated-shape">
        <div className="row g-5 mt--25">
          <div className="col-lg-12">
            <div
              className="section-title text-center"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <span className="pre-title mt-2">
                Partners
              </span>
              <h3 className="title">Affiliated with</h3>
            </div>
          </div>
          <div className="col-lg-12 col-12">
            <div className="testimonial-activation testimonial-item-1 testimonial-style-1 edu-slick-button slick-button-left logo-ratio">
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                autoplay={{
                  delay: 1000, // Delay in milliseconds
                  disableOnInteraction: false, // Enable navigation arrows while hovering
                }}
                modules={[FreeMode, Pagination, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="single-testimonial logo-ratio  mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/logo/1.png"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/logo/2.jpeg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial  mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/logo/3.png"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/logo/4.png"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logos;
