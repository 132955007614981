import React from "react";
import { Link } from "react-router-dom";

function CoursesCard() {
  return (
    <div className="edu-course-area  bg-color-white my-5">
      <div className="container">
        <div className="row g-5 mt--10">
          <div
            className="col-12 col-sm-6 col-lg-6"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="edu-card card-type-1 radius-small">
              <div className="inner">
                <div className="thumbnail">
                  <Link to="/">
                    <img
                      className="w-100"
                      src="assets/images/course/4.jpeg"
                      alt="Course Meta"
                    />
                  </Link>
                  <div className="top-position status-group left-top">
                    <span className="eduvibe-status status-01">
                      Intermediate
                    </span>
                  </div>
                </div>
                <div className="content">
                  <ul className="edu-meta meta-01">
                    <li>
                      <i className="icon-file-list-4-line"></i>44 Lessons
                    </li>
                    <li>
                      <i className="icon-time-line"></i>40h
                    </li>
                  </ul>
                  <h6 className="title">RBT</h6>
                  <div className="card-bottom">
                    <div className="price-list price-style-03">
                      <div className="price current-price">$80</div>
                    </div>
                    <ul className="edu-meta meta-01">
                      <li>
                        <i className="icon-account-circle-line"></i>85 Students
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-lg-6"
            data-sal-delay="200"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="edu-card card-type-1 radius-small">
              <div className="inner">
                <div className="thumbnail">
                  <Link to="/ABT-Course">
                    <img
                      className="w-100"
                      src="assets/images/course/3.jpeg"
                      alt="Course Meta"
                    />
                  </Link>
                  <div className="top-position status-group left-top">
                    <span className="eduvibe-status status-01">
                      {" "}
                      Intermediate
                    </span>
                  </div>
                </div>
                <div className="content">
                  <ul className="edu-meta meta-01">
                    <li>
                      <i className="icon-file-list-4-line"></i>44 Lessons
                    </li>
                    <li>
                      <i className="icon-time-line"></i>40h
                    </li>
                  </ul>
                  <Link to={"/ABT-Course"}>
                    <h6 className="title">
                      Applied Behavior Analysis Technician (ABAT) Course{" "}
                    </h6>
                  </Link>
                  <div className="card-bottom">
                    <div className="price-list price-style-03">
                      <div className="price current-price">$80</div>
                      <div className="price old-price">$100</div>
                    </div>
                    <ul className="edu-meta meta-01">
                      <li>
                        <i className="icon-account-circle-line"></i>59 Students
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-6 col-lg-6"
            data-sal-delay="250"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="edu-card card-type-1 radius-small">
              <div className="inner">
                <div className="thumbnail">
                  <Link to="/IBT-Course">
                    <img
                      className="w-100"
                      src="assets/images/course/2.jpeg"
                      alt="Course Meta"
                    />
                  </Link>
                  <div className="top-position status-group left-top">
                    <span className="eduvibe-status status-01">
                      {" "}
                      Intermediate
                    </span>
                  </div>
                </div>
                <div className="content">
                  <ul className="edu-meta meta-01">
                    <li>
                      <i className="icon-file-list-4-line"></i>44 Lessons
                    </li>
                    <li>
                      <i className="icon-time-line"></i>40h
                    </li>
                  </ul>
                  <Link to={"/IBT-Course"}>
                    <h6 className="title">
                      International Behavior Therapist Training Course (IBT)
                      Course
                    </h6>
                  </Link>
                  <div className="card-bottom">
                    <div className="price-list price-style-03">
                      <div className="price current-price">$80</div>
                      <div className="price old-price">$100</div>
                    </div>
                    <ul className="edu-meta meta-01">
                      <li>
                        <i className="icon-account-circle-line"></i>75 Students
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-lg-6 mb-5"
            data-sal-delay="300"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="edu-card card-type-1 radius-small">
              <div className="inner">
                <div className="thumbnail">
                  <Link to="/">
                    <img
                      className="w-100"
                      src="assets/images/course/1.jpeg"
                      alt="Course Meta"
                    />
                  </Link>
                  <div className="top-position status-group left-top">
                    <span className="eduvibe-status status-01">
                      Intermediate
                    </span>
                  </div>
                </div>
                <div className="content">
                  <ul className="edu-meta meta-01">
                    <li>
                      <i className="icon-file-list-4-line"></i>44 Lessons
                    </li>
                    <li>
                      <i className="icon-time-line"></i>52h
                    </li>
                  </ul>
                  <h6 className="title">Combined Course</h6>
                  <div className="card-bottom">
                    <div className="price-list price-style-03">
                      <div className="price current-price">$100</div>
                      <div className="price old-price">$200</div>
                    </div>
                    <ul className="edu-meta meta-01">
                      <li>
                        <i className="icon-account-circle-line"></i>57 Students
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoursesCard;
