import React from "react";
import { Link } from "react-router-dom";

function BreadCrumb(props) {
  return (
    <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner text-start">
              <div className="page-title">
                <h4 className="title">{props.title}</h4>
              </div>
              <nav className="edu-breadcrumb-nav">
                <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                  <li className="breadcrumb-item">
                    <Link className="bolder fs-3" to="/" style={{color:'#f9b01e'}}>Home</Link>
                  </li>
                  <li className="separator">
                    <i className="ri-arrow-drop-right-line"></i>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {props.title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-2 bread4-img">
              <img
                src="assets/images/about/about-02/bread4.png"
                alt="Shape Thumb"
              />
            </div>
            <div className="shape-image shape-image-3 bread3-img">
              <img src="assets/images/about/about-02/bread3.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-5 bread2-img">
              <img src="assets/images/about/about-02/bread2.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-6 bread1-img">
              <img
                src="assets/images/about/about-02/bread1.png"
                alt="Shape Thumb"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreadCrumb;
