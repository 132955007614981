import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import BreadCrumb from '../common/BreadCrumb'
import Privacy from './Privacy'

function Main() {
  return (
    <div className='main-wrapper'>
      <Header/>
      <BreadCrumb title="Privacy And Policy"/>
      <Privacy/>
      <Footer/>
    </div>
  )
}

export default Main
