import React, { useEffect } from "react";
import Main from "../component/InstructorProfile/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function Instructor() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Our Team | Learning Chip</title>
        <meta
          name="description"
          content="Meet the dedicated team behind Learning Chip - The first leading ABA therapy and learning center in Sahiwal, Punjab. Learn about our experienced educators, therapists, and professionals committed to providing inclusive education and support for differently-abled children and their families."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default Instructor;
