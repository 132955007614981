import React from "react";

function Profile4() {
  return (
    <article className="postcard light blue">
      <div className="postcard__img_link">
        <img
          src="assets/images/instructor/profile/profile3.jpeg"
          className="postcard__img"
          alt="head"
        />
      </div>
      <div className="postcard__text t-dark">
        <h1 className="postcard__title blue">
          <h5> Kiran Shanaz </h5>
        </h1>
        <div className="postcard__subtitle bolder" style={{ color: "#f9b01e" }}>
          Art Therapist/ ABA Therapist
        </div>
        <div className="postcard__bar"></div>
        <div className="postcard__preview-txt">
          Since 2021, Kiran has been working as an art therapist and behavior
          therapist at Learning Chip. She finished her 40-hour RBT training
          after completing her 3 years of DDM undergraduate coursework at Govt.
          College of Technology Sahiwal. She currently works as a behavioral
          therapist and art therapist with kids with autism.
        </div>
      </div>
    </article>
  );
}

export default Profile4;
