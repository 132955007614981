import React, { useEffect } from "react";
import Header from "../component/common/Header";
import BreadCrumb from "../component/common/BreadCrumb";
import Footer from "../component/common/Footer";

function Forget() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <BreadCrumb title="Forget Password" />
      <div className="login-register-page-wrapper edu-section-gap bg-color-white">
        <div className="container checkout-page-style">
          <div className="row g-5">
            <div className="d-flex justify-content-center">
              <div className="col-lg-6">
                <div className="login-form-box">
                  <h3 className="mb-30">Forget Password?</h3>
                  <form className="login-form" action="#">
                    <div className="input-box mb--30">
                      <input type="text" placeholder="Your Email..." />
                    </div>
                    <button
                      className="rn-btn edu-btn w-100 mb--30"
                      type="submit"
                    >
                      <span>Login</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Forget;
