import React, { useEffect } from "react";
import Main from "../component/faq/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function FAQ() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>FAQS | Learning Chip</title>
        <meta
          name="description"
          content="Explore frequently asked questions (FAQs) about ABA therapy, educational support, and developmental challenges on the Learning Chip website. Get answers to common queries about our services, programs, and resources for differently-abled children and their families."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default FAQ;
