import React, { useEffect } from "react";
import Main from "../component/courses/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function OurCourses() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Our Courses | Learning Chip</title>
        <meta
          name="description"
          content="Discover courses offered by Learning Chip - The first leading ABA therapy and learning center in Sahiwal, Punjab. Explore our curriculum, objectives, and benefits of each course designed to provide comprehensive support and education for differently-abled children."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default OurCourses;
