import React from "react";
import Module1 from "./Module1";
import Module2 from "./Module2";
import Module3 from "./Module3";
import Module4 from "./Module4";
import Module5 from "./Module5";
import Module6 from "./Module6";
import { Link } from "react-router-dom";

function IBT() {
  return (
    <div>
      <div className="edu-course-details-area edu-section-gap bg-color-white">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12">
              <div className="main-image thumbnail">
                <img
                  className="radius-small"
                  src="assets/images/course/2.jpeg"
                  alt="Banner Images"
                />
              </div>
            </div>
          </div>

          <div className="row g-5">
            <div className="col-xl-8 col-lg-7">
              <div className="course-details-content">
                <h3 className="title mt-5">
                  International Behavior Therapist Training Course
                </h3>
                <ul
                  className="edu-course-tab nav nav-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="overview-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#overview"
                      type="button"
                      role="tab"
                      aria-controls="overview"
                      aria-selected="true"
                    >
                      Overview
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="curriculum-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#curriculum"
                      type="button"
                      role="tab"
                      aria-controls="curriculum"
                      aria-selected="false"
                    >
                      Course Details
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="instructor-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#instructor"
                      type="button"
                      role="tab"
                      aria-controls="instructor"
                      aria-selected="false"
                    >
                      Instructor
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="overview"
                    role="tabpanel"
                    aria-labelledby="overview-tab"
                  >
                    <div className="course-tab-content">
                      <h5>Course Description</h5>
                      <p>
                        This IBT Training Course work includes the presentation
                        of Behavior Analytic Content to prepare Participants to
                        meet critical Competencies in the field. This 40-Hours
                        coursework covers foundational knowledge of Applied
                        Behavioral Analysis. Including Data Collection
                        techniques, Antecedent and Consequences based
                        Interventions, Basic Understanding of Assessment
                        Procedures, and Instructional Strategies, to deal with
                        challenging Behaviors as well as to develop more
                        appropriate behaviors. This Course work also includes
                        the ethical guidelines for IBT to maintain ethical
                        standards of Professionalism. Above all this course
                        provides a brief understanding of characteristics of
                        Intellectual disabilities and neurodevelopment
                        Disorders. There are Seven Modules in this course .Each
                        Module have several units and sub units and Reading
                        Assignments as well as practical activities of learned
                        strategies.
                      </p>
                      <h5>What You’ll Learn From This Course</h5>
                      <ul>
                        <li>
                          Neque sodales ut etiam sit amet nisl purus non tellus
                          orci ac auctor
                        </li>
                        <li>
                          Tristique nulla aliquet enim tortor at auctor urna.
                          Sit amet aliquam id diam maer
                        </li>
                        <li>
                          Nam libero justo laoreet sit amet. Lacus sed viverra
                          tellus in hac
                        </li>
                        <li>
                          Tempus imperdiet nulla malesuada pellentesque elit
                          eget gravida cum sociis
                        </li>
                      </ul>
                      <h5>Certification</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan lacus vel
                        facilisis.
                      </p>
                      <h5>
                        What is the International Behavior Therapist® (IBT®)
                        Certification?
                      </h5>
                      <p>
                        The International Behavior Therapist (IBT) certification
                        is obtained after a candidate demonstrates they have
                        completed a 40-hour training, 300 hours of supervised
                        practice, and supervision-related tasks and activities.
                        The IBT is an accomplishment that shows the world that
                        the individual is a competent behavior therapist and
                        ready to provide effective, ethical, behavioral services
                        in homes, schools, and a variety of community settings.
                      </p>
                      <h5>Competency Standards for IBT Certification</h5>
                      <p>
                        According to IBAO In order for a candidate to be
                        eligible for the IBT credential, The Candidate must be
                        at least 18 years old Possess a minimum of a high school
                        diploma or national equivalent, The IBT requirements
                        includes:
                      </p>
                      <h5>1. Registration</h5>
                      <p>
                        Candidates have to create their account at
                        www.theibao.com and pay fees.
                      </p>
                      <h5>2. Completion of Education Requirements</h5>
                      <p>
                        Candidates have to complete 40 hours of training on the
                        IBT educational content. The 40 training can be acquired
                        from multiple sources. The 40 hours training could be
                        completed in as little as one week. The training will
                        typically require 2 to 10 weeks to complete. Once
                        completed, the candidate is required to upload proof of
                        completion of the 40 Hour Training into their online
                        account.
                      </p>
                      <h5>3. Candidate Skills Testing</h5>
                      <p>
                        Candidate Skills Testing is observation of candidate’s
                        implementation of skills based on the roles and skills
                        being demonstrated during the supervised practice hours
                        by candidate's supervisor. CST can be different for
                        every candidate. CSTs can be conducted, for example, on
                        data collection, discrete trials implementation,
                        language acquisition programs, functional skills
                        programs, assessments, reinforcement programs, graphing,
                        etc. candidate’s performance while demonstrating the
                        chosen skill. The candidate's supervisor is required to
                        submit Candidate Skills Testing Approval Forms to the
                        supervisor's account.
                      </p>
                      <h5>4. Complete 4 Hours of Continuing Education</h5>
                      <p>
                        Four (4) hours of continuing education are required
                        prior to certification. Every candidate will need to
                        document CEUs obtained. Documentation requires a
                        physical document that clearly shows the content and
                        duration of the training event, when the training
                        occurred, and which organization or individual provided
                        the training. The following types of CEUs are
                        acceptable: Participation in online or in-person
                        seminars, workshops, or trainings on ABA or BA-related
                        information Presentation of a seminar, workshop, or
                        training on ABA or Behavior Analysis (BA) related
                        information Continuing Education.
                      </p>
                      <h5>Pre-certification CEUs</h5>
                      <p>4 Continuing Education Units (CEUs) are Required</p>
                      <li>1 Hour of Ethics</li>
                      <li>1 Hour in Cultural Diversity and Awareness</li>
                      <li>2 Hours in an other ABA Topics</li>
                      <br />
                      <h5>Supervision Requirements</h5>
                      <p>
                        At least 300 supervised practice hours are required to
                        sit for the IBT exam. One (1) hour of supervision is
                        required for every 30 hours of practice. There are no
                        restrictions on the number of hours that can be
                        completed as direct practice hours. The supervision can
                        occur at any time within the next 30 hours. All
                        supervision hours may be completed via distance methods.
                        Supervised practice hours can begin immediately upon
                        registration and signing the Supervisor Agreement. These
                        hours can accrue while the candidate is obtaining the 40
                        hours of training. The 40 hours of training do not have
                        to be completed prior to the start of the supervised
                        practice hours.
                      </p>
                      <h5>Passing The IBT Online Exam.</h5>
                      <p>
                        When all requirements have been met, IBT candidates
                        register for and sit for the IBT Online Exam.
                      </p>
                      <h5>IBT Exam Details</h5>
                      <li>Online Exam, No Test Centres</li>
                      <li>Online and Virtual Proctoring</li>
                      <li>75 Multiple Choice or True or False Questions</li>
                      <li>2 Hours to Complete Exam</li>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="curriculum"
                    role="tabpanel"
                    aria-labelledby="curriculum-tab"
                  >
                    <div className="course-tab-content">
                      <Module1 />
                      <Module2 />
                      <Module3 />
                      <Module4 />
                      <Module5 />
                      <Module6 />
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="instructor"
                    role="tabpanel"
                    aria-labelledby="instructor-tab"
                  >
                    <div className="course-tab-content">
                      <div className="course-author-wrapper">
                        <div className="thumbnail">
                          <img
                            src="assets/images/instructor/instructor-02/head.png"
                            alt="Author Images"
                          />
                        </div>
                        <div className="author-content">
                          <h6 className="title">
                            <a href="instructor-profile.html">Fareeha Kashif</a>
                          </h6>
                          <span className="subtitle">
                            BCaBA/IBA/QBA/M.A (SPL EDU)
                          </span>
                          <p>
                            Fareeha Kashif is clinical director of Learning
                            Chip. She has behavior analyst certification from
                            three American Boards of Behavior Analysis.She is a
                            Board-Certified Behavior Analyst (BCaBA), a
                            Qualified Behavior Analyst (QBA) as well as
                            International Behavior Analyst (IBA). She received
                            her Post Graduate Certification in Applied Behavior
                            Analysis from Florida Institute of Technology in
                            Melbourne, USA. She has finished her 8 hours of
                            supervision training in order to provide ongoing
                            supervision to RBTs/IBTs/ABATs. She has completed
                            her master’s degree in special education. Her two
                            autistic sons are her pride and joy, and she has
                            spent the past 19 years assisting those with special
                            needs as a parent and 7 years as a professional. She
                            had conducted multiple ABA trainings.
                          </p>
                          <ul className="social-share">
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.facebook.com/profile.php?id=100064471941364&mibextid=ZbWKwL"
                              >
                                <i className="icon-Fb"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.linkedin.com/in/fareeha-kashif-b60738168"
                              >
                                <i className="icon-linkedin"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://youtube.com/@fraeehakashif-behavioranal9357"
                              >
                                <i className="icon-youtube"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.instagram.com/learningchip?igsh=d3F2Z2U3OWxpdzl4"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-instagram mb-2"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                </svg>{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="review"
                    role="tabpanel"
                    aria-labelledby="review-tab"
                  >
                    <div className="course-tab-content">
                      <div className="row row--30">
                        <div className="col-lg-4">
                          <div className="rating-box">
                            <div className="rating-number">5.0</div>
                            <div className="rating">
                              <i className="icon-Star"></i>
                              <i className="icon-Star"></i>
                              <i className="icon-Star"></i>
                              <i className="icon-Star"></i>
                              <i className="icon-Star"></i>
                            </div>
                            <span>(25 Review)</span>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="review-wrapper">
                            <div className="single-progress-bar">
                              <div className="rating-text">
                                5 <i className="icon-Star"></i>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "100%" }}
                                  aria-valuenow="100"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <span className="rating-value">1</span>
                            </div>

                            <div className="single-progress-bar">
                              <div className="rating-text">
                                4 <i className="icon-Star"></i>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "0%" }}
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <span className="rating-value">0</span>
                            </div>

                            <div className="single-progress-bar">
                              <div className="rating-text">
                                3 <i className="icon-Star"></i>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "0%" }}
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <span className="rating-value">0</span>
                            </div>

                            <div className="single-progress-bar">
                              <div className="rating-text">
                                2 <i className="icon-Star"></i>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "0%" }}
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <span className="rating-value">0</span>
                            </div>

                            <div className="single-progress-bar">
                              <div className="rating-text">
                                1 <i className="icon-Star"></i>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "0%" }}
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <span className="rating-value">0</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-5">
              <div className="eduvibe-sidebar course-details-sidebar">
                <div className="inner">
                  <div className="eduvibe-widget">
                    <div className="video-area">
                      <div className="thumbnail video-popup-wrapper">
                        <img
                          className="radius-small w-100"
                          src="assets/images/course/2.jpeg"
                          alt="Course Images"
                        />
                      </div>
                    </div>
                    <div className="eduvibe-widget-details mt--35">
                      <div className="widget-content">
                        <ul>
                          <li>
                            <span>
                              <i className="icon-time-line"></i>Time
                            </span>
                            <span>43hrs</span>
                          </li>

                          <li>
                            <span>
                              <i className="icon-user-2"></i>Modules
                            </span>
                            <span>7</span>
                          </li>

                          <li>
                            <span>
                              <i className="icon-draft-line"></i>Lectures
                            </span>
                            <span>43</span>
                          </li>

                          <li>
                            <span>
                              <i className="icon-bar-chart-2-line"></i>Skill
                              Level
                            </span>
                            <span>Intermediate</span>
                          </li>

                          <li>
                            <span>
                              <i className="icon-translate"></i>Language
                            </span>
                            <span>English</span>
                          </li>

                          <li>
                            <span>
                              <i className="icon-artboard-line"></i> Quizzes
                            </span>
                            <span>8</span>
                          </li>

                          <li>
                            <span>
                              <i className="icon-award-line"></i> Certificate
                            </span>
                            <span>Yes</span>
                          </li>
                          {/* 
                          <li>
                            <span>
                              <img
                                className="eduvibe-course-sidebar-img-icon"
                                src="assets/images/icons/percent.svg"
                                alt="icon Thumb"
                              />{" "}
                              Pass Percentage
                            </span>
                            <span>90%</span>
                          </li> */}

                          <li>
                            <span>
                              <i className="icon-calendar-2-line"></i>Deadline
                            </span>
                            <span>180 days from start date</span>
                          </li>

                          <li>
                            <span>
                              <i className="icon-user-2-line_tie"></i>{" "}
                              Instructor
                            </span>
                            <span>Fareeha Kashif</span>
                          </li>
                        </ul>

                        <div className="read-more-btn mt--45">
                          <Link
                            className="edu-btn btn-bg-alt w-100 text-center"
                            to="/"
                          >
                            Price: $80.00
                          </Link>
                        </div>

                        <div className="read-more-btn mt--15">
                          <Link className="edu-btn w-100 text-center" to="/">
                            Buy Now
                          </Link>
                        </div>

                        <div className="read-more-btn mt--30 text-center">
                          <div className="eduvibe-post-share">
                            <span style={{color:"#f9b01e"}}>Share: </span>
                            <Link
                              target="_blank"
                              to="https://www.facebook.com/profile.php?id=100064471941364&mibextid=ZbWKwL"
                            >
                              <i className="icon-Fb"></i>
                            </Link>
                            <Link
                              target="_blank"
                              to="https://www.linkedin.com/in/fareeha-kashif-b60738168"
                            >
                              <i className="icon-linkedin"></i>
                            </Link>

                            <Link
                              target="_blank"
                              to="https://youtube.com/@fraeehakashif-behavioranal9357"
                            >
                              <i className="icon-youtube"></i>
                            </Link>

                            <Link
                              target="_blank"
                              to="https://www.instagram.com/learningchip?igsh=d3F2Z2U3OWxpdzl4"
                            >
                              <svg
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-instagram mb-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                              </svg>{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IBT;
