import React from "react";
import { Link } from "react-router-dom";

function NeedHelp() {
  return (
    <>
      <div className="edu-contact-us-area eduvibe-contact-us edu-section-gap bg-color-white">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">Need Help?</span>
                <h3 className="title">Get In Touch With us</h3>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--20">
            <div className="col-lg-6">
              <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                <div className="row g-5">
                  <div
                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div className="contact-address-card-1 website">
                      <div className="inner">
                        <div className="icon">
                          <i className="ri-global-line"></i>
                        </div>
                        <div className="content">
                          <h6 className="title">Our Website</h6>
                          <p>
                            <Link
                              to="https://learningchip.org/"
                              target="_blank"
                            >
                              learningchip.org
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                    data-sal-delay="200"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div className="contact-address-card-1 phone">
                      <div className="inner">
                        <div className="icon">
                          <i className="icon-Headphone"></i>
                        </div>
                        <div className="content">
                          <h6 className="title">Call Us On</h6>
                          <p>
                            <a href="tel:+92 324 4870414">(+92) 324 4870414</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                    data-sal-delay="250"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div className="contact-address-card-1 email">
                      <div className="inner">
                        <div className="icon">
                          <i className="icon-mail-open-line"></i>
                        </div>
                        <div className="content">
                          <h6 className="title">Email Us</h6>
                          <p>
                            <Link
                              to="mailto:info@learningchip.com"
                              target="_blank"
                            >
                              info@learningchip.com
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                    data-sal-delay="300"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div className="contact-address-card-1 location">
                      <div className="inner">
                        <div className="icon">
                          <i className="icon-map-pin-line"></i>
                        </div>
                        <div className="content">
                          <h6 className="title">Our Location</h6>
                          <p>Sahiwal, Punjab, Pakistan</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <form
                className="rnt-contact-form rwt-dynamic-form row"
                id="contact-form"
                method="POST"
                action="mail.php.html"
              >
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      name="contact-name"
                      id="contact-name"
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Name*"
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="contact-email"
                      name="contact-email"
                      placeholder="Email*"
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      name="contact-phone"
                      id="contact-phone"
                      placeholder="Phone"
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="subject"
                      name="subject"
                      placeholder="Subject"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="contact-message"
                      id="contact-message"
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    className="rn-btn edu-btn w-100"
                    name="submit"
                    type="submit"
                  >
                    <span>Submit Now</span>
                    <i className="icon-arrow-right-line-right"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NeedHelp;
