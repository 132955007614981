import React, { useEffect } from "react";
import BlogDetails from "../component/blog/BlogDetails";
import Header from "../component/common/Header";
import BreadCrumb from "../component/common/BreadCrumb";
import Footer from "../component/common/Footer";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function BlogDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Blog Details | Learning Chip</title>
        <meta
          name="description"
          content="Read insightful article 'Title of the Blog Post' on the Learning Chip blog. Explore valuable information about ABA therapy, educational support, and developmental challenges, and discover practical tips and strategies to support differently-abled children and their families."
        />
      </Helmet>
      <Animation />
      <Header />
      <BreadCrumb title="Blog Details" />
      <BlogDetails />
      <Footer />
    </div>
  );
}

export default BlogDetail;
