import  { useEffect } from 'react'
import sal from 'sal.js';
import "sal.js/dist/sal.css";

function Animation() {

    useEffect(() => {
        sal({});
    }, []);

  return null;
}

export default Animation
