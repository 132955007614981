import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="eduvibe-footer-one edu-footer footer-style-default">
      <div className="footer-top">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="edu-footer-widget">
                <div className="logo">
                  <a href="index.html">
                    <img
                      className="logo-light"
                      src="assets/images/logo/logo.png"
                      alt="Site Logo"
                      style={{ maxHeight: "150px" }}
                    />
                  </a>
                </div>
                <p className="description">
                  We Provide learning that breed Confidence, Hope, Inspiration
                  and Peace based on ABA Therapy.
                </p>
                <ul className="social-share">
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.facebook.com/profile.php?id=100064471941364&mibextid=ZbWKwL"
                    >
                      <i className="icon-Fb"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.linkedin.com/in/fareeha-kashif-b60738168"
                    >
                      <i className="icon-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://youtube.com/@fraeehakashif-behavioranal9357"
                    >
                      <i className="icon-youtube"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      to="https://www.instagram.com/learningchip?igsh=d3F2Z2U3OWxpdzl4"
                    >
                      <svg
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-instagram mb-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                      </svg>{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="edu-footer-widget explore-widget">
                <h5 className="widget-title">Explore</h5>
                <div className="inner">
                  <ul className="footer-link link-hover">
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/our-courses">Our Courses</Link>
                    </li>
                    <li>
                      <Link to="/event">Events</Link>
                    </li>
                    <li>
                      <Link to="/media">Media</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blogs</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="edu-footer-widget quick-link-widget">
                <h5 className="widget-title">Useful Links</h5>
                <div className="inner">
                  <ul className="footer-link link-hover">
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/team">Our Team</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy & Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="edu-footer-widget">
                <h5 className="widget-title">Contact Info</h5>
                <div className="inner">
                  <div className="widget-information">
                    <ul className="information-list">
                      <li>
                        <i className="icon-map-pin-line"></i>
                        Sahiwal, Punjab, Pakistan{" "}
                      </li>
                      <li>
                        <i className="icon-phone-fill"></i>
                        <Link to="tel:(+92) 324 4870414">
                          (+92) 324 4870414
                        </Link>
                      </li>
                      <li>
                        <i className="icon-mail-line-2"></i>
                        <Link target="_blank" to="#">
                          info@learningchip.com{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area copyright-default">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <p class="copyright">
                  © 2024 <span>Learningchip</span>.{" "}
                  <span>
                    Developed by{" "}
                    <Link
                      className="fw-bold "
                      style={{ color: "#f9b01e" }}
                      to="https://www.tech-786.com"
                      target="_blank"
                    >
                      {" "}
                      Tech786
                    </Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="purchase-now" className="text-center">
        <a href="https://wa.me/923244870414" rel="noreferrer" target="_blank">
          <img src="/assets/images/icons/whatsapp.png" width={"100%"} height={"100%"} alt="whatsapp icon" />
          <span className="text-nowrap">Chat Now</span>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
