import React from "react";

function Module3() {
  return (
    <div className="course-tab-content">
      <h6 className="courses-clr">Module 3: Measuring Behavior (IBTC-03)</h6>
      <p className="description">
        <span className="text-dark fs-2 fw-bold">Course Description: </span>This
        5-hours Training module consists of 6 units. This covers strategies to
        measure targeted behavior and its visual representation for data
        analysis. Participants will be able to gain understandings about
        continuous and discontinuous data collection procedures as well as
        graphing skills.
      </p>
      <div className="edu-accordion-02" id="accordionExample3">
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="headingOne">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Unit-1 Data Collection Preparation Activities (IBTC- 3.1)
            </button>
          </div>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Operational Definition of Behavior{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List 3 main characteristics of Operational Definition{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Provide examples vs. non examples of Operational definition
                    of Behavior{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Importance of Precise Behavioral Definitions{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Data
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    State Significance of Data Collection{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Raw Data{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Required Tools for Data collection{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Data collection Process{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Steps for Data Collection{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Describe How to rule out the appropriate Data collection
                    Procedures{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Provide Sample Data collection Sheets{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List steps to create Data Collection Sheets{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading13">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse13"
              aria-expanded="false"
              aria-controls="collapse13"
            >
              Unit-2 Fundamental Properties and Dimensional Quantities (IBTC-
              3.2)
            </button>
          </div>
          <div
            id="collapse13"
            className="accordion-collapse collapse"
            aria-labelledby="heading13"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Fundamental
                    properties of behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List 3 types of
                    Fundamental properties of behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Dimensional
                    quantities of behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List associated
                    Dimensional quantities of behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Combination of 2
                    Fundamental properties
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Continuous
                    Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Discontinuous
                    Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading14">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse14"
              aria-expanded="false"
              aria-controls="collapse14"
            >
              Unit-3 Continues Measurement Procedures (IBTC- 3.3)
            </button>
          </div>
          <div
            id="collapse14"
            className="accordion-collapse collapse"
            aria-labelledby="heading14"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Continuous
                    Measurement Procedures
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Example Vs Non Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Use Of Continuous
                    Measurement Procedures
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-3A Frequency/Count (IBTC- 3.3A)
                    </h6>
                    <i className="icon-Double-arrow"></i>Define Frequency
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Example Vs Non Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Use of
                    Frequency Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe the procedures
                    of frequency Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Required Tools for
                    Frequency Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Limitations Of
                    Frequency Recordings
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-3B Duration (IBTC- 3.3b)
                    </h6>
                    <i className="icon-Double-arrow"></i>Define Duration
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Example Vs Non Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Duration per
                    Interval
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Duration per
                    occurrence
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define When To Use
                    Duration Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Required tools for
                    Duration Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Limitations Of Duration
                    Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-3C Latency (IBTC- 3.3C)
                    </h6>
                    <i className="icon-Double-arrow"></i>Define latency
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Example Vs Non Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define When To Use
                    Latency Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Required tools for
                    Latency Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading94">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse94"
              aria-expanded="false"
              aria-controls="collapse94"
            >
              Unit-4 Discontinuous Measurement Procedures (IBTC- 3.4)
            </button>
          </div>
          <div
            id="collapse94"
            className="accordion-collapse collapse"
            aria-labelledby="heading94"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Discontinuous
                    Measurement Procedures
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Example Vs Non Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Use of Discontinuous
                    Measurement Procedures
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-4A Partial Interval Recording (IBTC- 3.4A)
                    </h6>
                    <i className="icon-Double-arrow"></i>Define Partial interval
                    Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Steps to implement
                    Partial Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Limitations of
                    Partial Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define When to use
                    Partial Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide directions for
                    the use of partial interval recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-4B Whole Interval Recording (IBTC- 3.4B)
                    </h6>
                    <i className="icon-Double-arrow"></i>Define Whole Interval
                    Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Steps to Whole
                    implement Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Limitations of
                    whole Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State When to use Whole
                    Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide directions for
                    the use of Whole interval recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-4C Momentary Time Sampling (IBTC- 3.4C)
                    </h6>
                    <i className="icon-Double-arrow"></i>Define Momentary Time
                    Sampling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Steps to implement
                    Momentary Time Sampling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Limitations of
                    Momentary Time Sampling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State When to use
                    Momentary Time Sampling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide directions for
                    the use of Momentary Time Sampling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading15">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse15"
              aria-expanded="false"
              aria-controls="collapse15"
            >
              Unit-5 Permanent-Product Recording Procedures (IBTC- 3.5)
            </button>
          </div>
          <div
            id="collapse15"
            className="accordion-collapse collapse"
            aria-labelledby="heading15"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Permanent
                    -Product Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Example vs. Non
                    Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State When to use
                    Permanent-Product Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Advantages of
                    Permanent-Product Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Limitations of
                    Permanent-Product Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide directions for
                    the use of Permanent-Product Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading95">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse95"
              aria-expanded="false"
              aria-controls="collapse95"
            >
              Unit-6 Visual Representation of Data (IBTC-3.6)
            </button>
          </div>
          <div
            id="collapse95"
            className="accordion-collapse collapse"
            aria-labelledby="heading95"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Graphs
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe Purpose &
                    Benefits of Graphic Display
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Benefits of
                    Graphs
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Types of Graphs
                    Used in ABA
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-6A Equal Interval Line Graph (IBTC-3.6A)
                    </h6>
                    <i className="icon-Double-arrow"></i>Definition of Line
                    Graph
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List of the Parts of an
                    Equal Interval Line Graph
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define The horizontal
                    X-axis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define The vertical
                    Y-axis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State the conventions
                    for the relative length of the Y-axis compared to the X-axis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Data points
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define The data path
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Condition and
                    phase change lines
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Condition and
                    phase change labels
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Figure legend
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-6B Cumulative Record (IBTC- 3.6B)
                    </h6>
                    <i className="icon-Double-arrow"></i>Describe Cumulative
                    Records
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Give Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-6C Bar Graph (Histogram) (IBTC- 3.6C)
                    </h6>
                    <i className="icon-Double-arrow"></i>Bar Graph (Histogram)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe proper use
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe improper use
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-6D Semi-logarithmic Graphs/Standard Celeration Chart
                      (IBTC- 3.6D)
                    </h6>
                    <i className="icon-Double-arrow"></i>Define Semi-logarithmic
                    Graphs (Standard Celeration Chart)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Contrast with equal
                    interval line graphs
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Briefly discuss the use
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-6E Visual Analysis of Graphs (IBTC-3.6E)
                    </h6>
                    <i className="icon-Double-arrow"></i>Define Visual Data
                    Analysis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe the Purpose of
                    Visual Analysis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe the process of
                    Interpreting Graphically Displayed Data
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>4 characteristics of
                    the data plotted on the chart
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Level of data
                    (Mean &Median Level)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Variability in
                    Data
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Trend of Data
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define The number of
                    data points required for visual analysis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Module3;
