import React from "react";

function Profile3() {
  return (
    <article className="postcard light blue">
      <div className="postcard__img_link">
        <img
          className="postcard__img w-100"
          src="assets/images/instructor/profile/profile2.png"
          alt="Title"
        />
      </div>
      <div className="postcard__text t-dark">
        <h1 className="postcard__title blue">
          <h5> Amna Qayyum </h5>
        </h1>
        <div className="postcard__subtitle bolder" style={{ color: "#f9b01e" }}>
          ABA Therapist
        </div>
        <div className="postcard__bar"></div>
        <div className="postcard__preview-txt">
          Since 2022, Amna completed her 40-hour RBT training after receiving
          her bachelor degree in education, and she is currently employed as an
          ABA therapist at a learning Chip.
        </div>
      </div>
    </article>
  );
}

export default Profile3;
