import React from "react";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div className="slider-area banner-style-2 bg-image d-flex align-items-center">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-12 col-md-12">
            <div className="inner">
              <div className="content text-start">
                <span
                  className="pre-title"
                  style={{ color: "#fff" }}
                  data-sal-delay="150"
                  ZA
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  Philosophy of Learning{" "}
                  <span className="display-3 fw-bolder bolder hero-clr">
                    <span style={{ color: "#ff0100" }}>
                      C<span style={{ color: "#03b050" }}>H</span>
                      <span style={{ color: "#01b0f1" }}>I</span>
                      <span style={{ color: "#7030a8" }}>P</span>
                    </span>{" "}
                  </span>
                </span>
                <h1
                  className="title"
                  style={{ color: "#e96a47" }}
                  data-sal-delay="250"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  Peace Breeds Inspiration
                </h1>
                <p
                  className="description glow-text"
                  style={{ color: "#fff" }}
                  data-sal-delay="350"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  " Education breeds{" "}
                  <span className="fs-1 fw-bolder" style={{ color: "#ff0100" }}>
                    C
                  </span>
                  onfidence. Confidence breeds{" "}
                  <span className="fs-1 fw-bolder" style={{ color: "#03b050" }}>
                    H
                  </span>
                  ope. Hope breeds{" "}
                  <span className="fs-1 fw-bolder" style={{ color: "#7030a0" }}>
                    P
                  </span>
                  eace. "
                  - CONFUCIUS
                </p>
                <Link
                  className="edu-btn"
                  to="/contact-us"
                  data-sal-delay="450"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  Get Started Today{" "}
                  <i className="icon-arrow-right-line-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
