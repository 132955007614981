import React  from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";

function Testimonials() {
  return (
    <div className=" edu-testimonial-area edu-section-gap bg-image">
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="section-title text-center"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <span className="pre-title">Testimonial</span>
              <h3 className="title">what our course participants says</h3>
            </div>
          </div>
        </div>
        <div className="row g-5 mt--25">
          <div className="col-lg-12 col-12">
            <div className="testimonial-activation testimonial-item-1 testimonial-style-1 edu-slick-button slick-button-left">
              <Swiper
                slidesPerView={4}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                autoplay={{
                  delay: 1000, // Delay in milliseconds
                  disableOnInteraction: false, // Enable navigation arrows while hovering
                }}
                modules={[FreeMode, Pagination, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/1.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/2.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/3.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/4.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/5.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/6.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/7.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/8.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/9.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/10.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/11.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/12.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/13.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/14.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/15.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/16.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/17.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/18.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/19.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/20.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/21.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/22.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/23.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/24.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/25.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-testimonial mb-5">
                    <div className="inner">
                      <div className="quote-sign">
                        <img
                          src="assets/images/Website Images/Testimonials/26.jpg"
                          alt="Quote Images"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
