import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

function Header() {
  useEffect(() => {
    $(".hamberger-button").on("click", function () {
      $(".popup-mobile-menu").addClass("active");
    });

    $(".close-menu").on("click", function () {
      $(".popup-mobile-menu").removeClass("active");
      $(".popup-mobile-menu .mainmenu .has-droupdown > a")
        .siblings(".submenu, .rn-megamenu")
        .removeClass("active")
        .slideUp("400");
      $(".popup-mobile-menu .mainmenu .has-droupdown > a").removeClass("open");
    });

    $(".popup-mobile-menu .mainmenu .has-droupdown > a").on(
      "click",
      function (e) {
        e.preventDefault();
        $(this)
          .siblings(".submenu, .rn-megamenu")
          .toggleClass("active")
          .slideToggle("400");
        $(this).toggleClass("open");
      }
    );

    $(".popup-mobile-menu").on("click", function (e) {
      if (e.target === this) {
        $(".popup-mobile-menu").removeClass("active");
        $(".popup-mobile-menu .mainmenu .has-droupdown > a")
          .siblings(".submenu, .rn-megamenu")
          .removeClass("active")
          .slideUp("400");
        $(".popup-mobile-menu .mainmenu .has-droupdown > a").removeClass(
          "open"
        );
      }
    });

    // Cleanup function to remove event listeners
    return () => {
      $(".hamberger-button").off("click");
      $(".close-menu").off("click");
      $(".popup-mobile-menu .mainmenu .has-droupdown > a").off("click");
      $(".popup-mobile-menu").off("click");
    };
  }, []);
  return (
    <>
      <header className="edu-header disable-transparent header-sticky header-style-2 header-default my-2">
        <div className="row align-items-center p-0 m-0 py-0">
          <div className="col-lg-2 col-md-6 col-6 p-0 m-0">
            <div className="logo">
              <a href="/">
                <img
                  className="logo-light"
                  src="assets/images/logo/logo.png"
                  alt="Site Logo"
                  width={"100%"}
                  height={"100%"}
                />
              </a>
            </div>
          </div>
          <div className="col-lg-8 d-none d-xl-block">
            <nav className="mainmenu-nav d-none d-xl-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/our-courses">Our Courses</Link>
                </li>
                <li>
                  <Link to="/event">Events</Link>
                </li>
                <li>
                  <Link to="/media">Media</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/blog">Blogs</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-lg-2 col-md-6 col-6">
            <div className="header-right d-flex justify-content-end">
              <div className="header-menu-bar">
                <div className="quote-icon quote-user d-none d-md-block ml--15 ml_sm--5">
                  <Link
                    className="edu-btn btn-medium left-icon header-button text-nowrap"
                    to="/login-register"
                  >
                    <i  className="ri-user-line"></i>Login / Register
                  </Link>
                </div>
                <div className="quote-icon quote-user d-block d-md-none ml--15 ml_sm--5">
                  <Link className="white-box-icon" to="/login-register">
                    <i className="ri-user-line"></i>
                  </Link>
                </div>
              </div>

              <div className="mobile-menu-bar ml--15 ml_sm--5 d-block d-xl-none">
                <div className="hamberger">
                  <button className="white-box-icon hamberger-button header-menu">
                    <i className="ri-menu-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="popup-mobile-menu">
        <div className="inner">
          <div className="header-top">
            <div className="logo">
              <a href="index.html">
                <img src="assets/images/logo/logo.png" alt="Site Logo" />
              </a>
            </div>
            <div className="close-menu">
              <button className="close-button">
                <i className="ri-close-line"></i>
              </button>
            </div>
          </div>
          <ul className="mainmenu">
            <li >
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/our-courses">Our Courses</Link>
            </li>
            <li>
              <Link to="/event">Events</Link>
            </li>
            <li>
              <Link to="/media">Media</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/blog">Blogs</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
