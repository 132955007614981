import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import BreadCrumb from "../common/BreadCrumb";
import EventCards from "./EventCards";
import AlliedCamp2017 from "./AlliedCamp2017";
import AlliedCamp2018 from "./AlliedCamp2018";
import AutismSeminar from "./AutismSeminar";
import CertificationCelebration from "./CertificationCelebration";
import InternationalDay from "./InternationalDay";
import Autism2018 from "./Autism2018";

function Main() {
  return (
    <div className="main-wrapper">
      <Header />
      <BreadCrumb title="Events" />
      <EventCards />
      <AlliedCamp2017 />
      <AlliedCamp2018 />
      <AutismSeminar />
      <CertificationCelebration />
      <InternationalDay />
      <Autism2018 />
      <Footer />
    </div>
  );
}

export default Main;
