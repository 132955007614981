import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import BreadCrumb from '../common/BreadCrumb'
import NeedHelp from './NeedHelp'

function Main() {
  return (
    <div className='main-wrapper'>
      <Header/>
      <BreadCrumb title="Contact Us"/>
      <NeedHelp/>
      <Footer/>
    </div>
  )
}

export default Main
