import React from "react";
import { Link } from "react-router-dom";

function Error() {
  return (
    <div className="eduvibe-404-page">
      <div className="main-wrapper ">
        <div className=" edu-error-style">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="content text-center">
                  <div className="thumbnail mb--50">
                    <img src="assets/images/others/404.png" alt="404 Images" />
                  </div>
                  <h3 className="title">Oops... Page Not Found!</h3>
                  <p className="description">
                    Please return to the site's homepage, It looks like nothing
                    was found at this location
                  </p>
                  <div className="backto-home-btn">
                    <Link className="edu-btn" to="/">
                      Back To Home
                      <i className="icon-arrow-right-line-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Error;
