import React, { useEffect } from "react";
import Animation from "../component/common/Animation";
import Main from "../component/IBT/Main";
import { Helmet } from "react-helmet";

function IBTCourse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>IBT Course | Learning Chip</title>
        <meta
          name="description"
          content="Learn about the Integrated Behavioral Therapy (IBT) course offered by Learning Chip. Explore the curriculum, objectives, and benefits of our IBT program, designed to provide comprehensive support and education for differently-abled children."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default IBTCourse;
