import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import sal from "sal.js";
import "sal.js/dist/sal.css";
import CourseDetail from "./pages/CourseDetail";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import OurCourses from "./pages/OurCourses";
import Services from "./pages/Services";
import LoginRegister from "./pages/LoginRegister";
import Instructor from "./pages/Instructor";
import Event from "./pages/Event";
import Media from "./pages/Media";
import FAQ from "./pages/FAQ";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import IBTCourse from "./pages/IBTCourse";
import Forget from "./pages/Forget";
import Error from "./pages/Error";
function App() {
  useEffect(() => {
    sal({});
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog-details" element={<BlogDetail />} />
        <Route exact path="/our-courses" element={<OurCourses />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/login-register" element={<LoginRegister />} />
        <Route exact path="/team" element={<Instructor />} />
        <Route exact path="/event" element={<Event />} />
        <Route exact path="/media" element={<Media />} />
        <Route exact path="/IBT-Course" element={<IBTCourse />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/ABT-Course" element={<CourseDetail />} />
        <Route exact path="/forget" element={<Forget />} />
        <Route exact path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
