import React, { useEffect } from "react";
import Main from "../component/Home/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Home | Learning Chip</title>
        <meta
          name="description"
          content="Welcome to Learning Chip - The first leading ABA therapy and learning center in Sahiwal, Punjab. We provide inclusive education and support for differently-abled children. Explore our services, programs, and resources to help every child thrive and reach their full potential."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default Home;
