import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import BreadCrumb from '../common/BreadCrumb'
import Hero from './Hero'
import OurServices from '../Home/OurServices'
import Testimonials from './Testimonials'
import Team from './Team'

function Main() {

  return (
    <div className='main-wrapper'>
      <Header/>
      <BreadCrumb title="About Us"/>
      <Hero/>
      <Team/>
      <OurServices/>
      <Testimonials/>
      <Footer/>
    </div>
  )
}

export default Main
