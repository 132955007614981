import React from "react";

function Profile1() {
  return (
    <article className="postcard light blue">
      <div className="postcard__img_link">
        <img
          className="postcard__img w-100"
          src="assets/images/instructor/profile/profile4.jpeg"
          alt="Title"
        />
      </div>
      <div className="postcard__text t-dark">
        <h1 className="postcard__title blue">
          <h5> Hafiza Malaika Kashif </h5>
        </h1>
        <div className="postcard__subtitle bolder" style={{ color: "#f9b01e" }}>
          Registered Behavior Technician (RBT){" "}
        </div>
        <div className="postcard__bar"></div>
        <div className="postcard__preview-txt">
          She has been working as the lead ABA therapist at Learning Chip since
          2021. She has a bachelor's degree in clinical psychology and her RBT
          certification from the BACB board USA. She has expertise working with
          autistic children and children with learning disabilities.
        </div>
      </div>
    </article>
  );
}

export default Profile1;
