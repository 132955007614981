import React from "react";

function Module7() {
  return (
    <div className="course-tab-content">
      <h6 className="courses-clr">
        Module 7: Data Collection and Data Analysis (ABATC-07)
      </h6>
      <p className="description">
        <span className="text-dark fs-2 fw-bold">Course Description: </span>This
        8-hours Training module consists of 13 units. This covers strategies to
        measure targeted behavior and its visual representation for data
        analysis. Participants will be able to gain understandings about
        continuous and discontinuous data collection procedures as well as
        graphing skills.
      </p>
      <div className="edu-accordion-02" id="accordionExample1">
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="headingOne">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Unit-1 Data Collection Preparation Activities (ABATC- 7.1)
            </button>
          </div>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Data
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Significance of
                    Data Collection
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Raw Data
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Required Tools for
                    Data collection
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Data collection
                    Process
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Steps for Data
                    Collection
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe How to rule
                    out the appropriate Data collection Procedures
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Sample Data
                    Collection Sheets
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List steps to create
                    Data Collection Sheets
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading50">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse50"
              aria-expanded="false"
              aria-controls="collapse50"
            >
              Unit-2 Characteristics of Operational definitions of Behavior
              (ABATC- 7.2)
            </button>
          </div>
          <div
            id="collapse50"
            className="accordion-collapse collapse"
            aria-labelledby="heading50"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Operational
                    Definition of Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List 3 main
                    characteristics of Operational Definition
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Role of Target
                    Behavior Definition in ABA
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define 2 Types of
                    Operational Definition
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Function-Based
                    definition
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide examples vs.
                    non examples of Function-Based definition of Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Topography-Based
                    Definitions
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide examples vs.
                    non examples of Topography-Based definition of Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Steps of writing
                    Target Behavior Definitions
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Importance of
                    Precise Behavioral Definition
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading51">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse51"
              aria-expanded="false"
              aria-controls="collapse51"
            >
              Unit-3 Preference Assessment (ABATC- 7.3)
            </button>
          </div>
          <div
            id="collapse51"
            className="accordion-collapse collapse"
            aria-labelledby="heading51"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                A) Define Stimulus Preference Assessment
              </h6>
              <h6 className="courses-clr">
                B) List Types of Stimulus Preference Assessment
              </h6>
              <h6 className="courses-clr">
                C) Define Free-Operant Methods of Preference Assessment
              </h6>
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Types of
                    Free-Operant Methods
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Naturalistic
                    Observation Methods
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Contrived
                    Observation Methods, Examples vs. on-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
              <h6 className="courses-clr">
                D) Define Asking about Stimulus Preference Assessment Method
              </h6>
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Types of Asking
                    about Preference Assessment Method
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Open-ended
                    Questions Methods, Examples vs. Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Asking
                    Significant others Methods, Examples vs. Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define offering a Pre
                    task Choice Methods, Examples vs. Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                E) Define Trial Based Methods of Preference Assessment
              </h6>
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Types of Trial- Based Methods of Preference Assessment{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Single Stimulus Preference Assessment{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Data Collection
                    Sheets Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Procedure of
                    Conducting Single Stimulus Preference Assessment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Paired Stimuli
                    Preference Assessment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Procedure of
                    Conducting Paired Stimulus Preference Assessment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Data Collection
                    Sheets Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Multiple Stimuli
                    Preference Assessment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List variations of
                    Multiple Stimuli Preference Assessment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Multiple Stimuli
                    with Replacement(MSW)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide MSW Data
                    Collection Sheets Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    DefineMultipleStimuliwithoutReplacement(MSW0)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    ProvideMSWODataCollectionSheetsExamples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <h6 className="courses-clr">
                  F) Define Guidelines for selecting and using Stimulus
                  Preference Assessments
                </h6>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading52">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse52"
              aria-expanded="false"
              aria-controls="collapse52"
            >
              Unit-4 Environmental Evaluations (ABATC- 7.4)
            </button>
          </div>
          <div
            id="collapse52"
            className="accordion-collapse collapse"
            aria-labelledby="heading52"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define
                    Ecological/Environmental Assessment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State when to use
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Types of
                    Ecological Assessment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define ABC Data
                    Collection
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Reactive Effects
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading53">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse53"
              aria-expanded="false"
              aria-controls="collapse53"
            >
              Unit-5 Indicators of Trustworthy Measurement (ABATC- 7.5)
            </button>
          </div>
          <div
            id="collapse53"
            className="accordion-collapse collapse"
            aria-labelledby="heading53"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Validity{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Accuracy{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Reliability{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Threats to Measurement (Validity){" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Threats to Measurement (Accuracy){" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Threats to Measurement (Reliability){" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Observer Reactivity{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Assessing the Accuracy of Behavioral Measurement{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Assessing the Reliability of Behavioral Measurement{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading54">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse54"
              aria-expanded="false"
              aria-controls="collapse54"
            >
              Unit-6 Measuring Behavior (ABATC- 7.6)
            </button>
          </div>
          <div
            id="collapse54"
            className="accordion-collapse collapse"
            aria-labelledby="heading54"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Measurement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Importance of
                    Measurement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading55">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse55"
              aria-expanded="false"
              aria-controls="collapse55"
            >
              Unit-7 Fundamental Properties and Dimensional Quantities (ABATC-
              7.7)
            </button>
          </div>
          <div
            id="collapse55"
            className="accordion-collapse collapse"
            aria-labelledby="heading55"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Fundamental properties of behavior{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List 3 types of Fundamental properties of behavior{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Dimensional quantities of behavior{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List associated Dimensional quantities of behavior{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Combination of 2 Fundamental properties{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Continuous Behavior{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Discontinuous Behavior{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading56">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse56"
              aria-expanded="false"
              aria-controls="collapse56"
            >
              Unit-8 Continuous Measurement Procedures (ABATC-7.8)
            </button>
          </div>
          <div
            id="collapse56"
            className="accordion-collapse collapse"
            aria-labelledby="heading56"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Continuous
                    Measurement Procedures
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Example Vs Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Use Of Continuous
                    Measurement Procedures
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                {" "}
                Unit-8A Frequency/Count (ABATC-7.8A)
              </h6>

              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Frequency
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Examples Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Use of
                    Frequency Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe the procedures
                    of frequency Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Required Tools for
                    Frequency Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Limitations Of
                    Frequency Recordings
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr"> Unit-8B Duration (ABATC- 7.8B)</h6>

              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Duration
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Examples Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Duration per
                    Interval
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Duration per
                    occurrence
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define When to Use
                    Duration Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Required tools for
                    Duration Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Limitations Of Duration
                    Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr"> Unit-8C Latency (ABATC- 7.8C)</h6>

              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define latency
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Examples Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define When to Use
                    Latency Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Required tools for
                    Latency Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading57">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse57"
              aria-expanded="false"
              aria-controls="collapse57"
            >
              Unit-9 Discontinuous Measurement Procedures (ABATC-7.9)
            </button>
          </div>
          <div
            id="collapse57"
            className="accordion-collapse collapse"
            aria-labelledby="heading57"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Discontinuous
                    Measurement Procedures
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Example Vs Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Use of Discontinuous
                    Measurement Procedures
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                {" "}
                Unit-9A Partial Interval Recording (ABATC-7.9A)
              </h6>

              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Partial interval
                    Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Steps to implement
                    Partial Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Limitations of
                    Partial Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define When to use
                    Partial Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide directions for
                    the use of partial interval recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                {" "}
                Unit-9B Whole Interval Recording (ABATC- 7.9B)
              </h6>

              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Whole Interval
                    Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Steps to Whole
                    implement Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Limitations of
                    whole Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State When to use Whole
                    Interval Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide directions for
                    the use of Whole interval recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                {" "}
                Unit-9C Momentary Time Sampling (ABATC- 7.9C)
              </h6>

              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Momentary Time
                    Sampling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Steps to implement
                    Momentary Time Sampling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Limitations of
                    Momentary Time Sampling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State When to use
                    Momentary Time Sampling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide directions for
                    the use of Momentary Time Sampling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading58">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse58"
              aria-expanded="false"
              aria-controls="collapse58"
            >
              Unit-10 Permanent-Product Recording Procedures (ABATC-7.10)
            </button>
          </div>
          <div
            id="collapse58"
            className="accordion-collapse collapse"
            aria-labelledby="heading58"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Permanent
                    -Product Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Example vs.
                    Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State When to use
                    Permanent-Product Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Advantages of
                    Permanent-Product Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Limitations of
                    Permanent-Product Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide directions for
                    the use of Permanent-Product Recording
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading59">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse59"
              aria-expanded="false"
              aria-controls="collapse59"
            >
              Unit-11 Visual Representation of Data (ABATC- 7.11)
            </button>
          </div>
          <div
            id="collapse59"
            className="accordion-collapse collapse"
            aria-labelledby="heading59"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Graphs
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe Purpose &
                    Benefits of Graphic Display
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Benefits of
                    Graphs
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Types of Graphs
                    Used in ABA
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                {" "}
                Unit-11A Equal Interval Line Graph (ABATC-7.11A)
              </h6>

              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Definition of Line
                    Graph
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List of the Parts of an
                    Equal Interval Line Graph
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define The horizontal
                    X-axis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define The vertical
                    Y-axis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State the conventions
                    for the relative length of the Y-axis compared to the X-axis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Data points
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define The data path
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Condition and
                    phase change lines
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Condition and
                    phase change labels
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Figure legend
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                {" "}
                Unit-11B Cumulative Record (ABATC-7.11B)
              </h6>

              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe Cumulative
                    Records
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Give Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                {" "}
                Unit-11C Bar Graph (Histogram) (ABATC- 7.11C)
              </h6>

              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Bar Graph (Histogram)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe proper use
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe improper use
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                {" "}
                Unit-11D Semi-logarithmic Graphs/Standard Cele ration Chart
                (ABATC-7.11D)
              </h6>

              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Semi-logarithmic
                    Graphs (Standard Cele ration Chart)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Contrast with equal
                    interval line graphs
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Briefly discuss the use
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading60">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse60"
              aria-expanded="false"
              aria-controls="collapse60"
            >
              Unit-12 Visual Analysis of Graphs (ABATC-7.12)
            </button>
          </div>
          <div
            id="collapse60"
            className="accordion-collapse collapse"
            aria-labelledby="heading60"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Visual Data Analysis{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Describe the Purpose of Visual Analysis{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Describe the process of Interpreting Graphically Displayed
                    Data{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>4 characteristics of
                    the data plotted on the chart{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Level of data (Mean &Median Level){" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Variability in Data{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Trend of Data{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define The number of data points required for visual
                    analysis{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading61">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse61"
              aria-expanded="false"
              aria-controls="collapse61"
            >
              Unit-13 Define and Identify the Benefits of IOA (ABATC- 7.13)
            </button>
          </div>
          <div
            id="collapse61"
            className="accordion-collapse collapse"
            aria-labelledby="heading61"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Interobserver agreement (IOA){" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Benefits and Uses of IOA{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Requisites for obtaining valid IOA Measures{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Believability{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Trustworthiness{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Methods of Calculating IOA{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List IOA for Data Obtained by Event Recording{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List IOA for Data Obtained by Timming{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List IOA for Data Obtained by Time Sampling/Interval
                    Recording{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Module7;
