import React, { useEffect } from "react";
import Main from "../component/LoginRegister/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";
function LoginRegister() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Login/Register | Learning Chip</title>
        <meta
          name="description"
          content="Login to Learning Chip - The first leading ABA therapy and learning center in Sahiwal, Punjab. Access your account to explore resources, schedule appointments, and engage with our community."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default LoginRegister;
