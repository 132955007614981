import React from "react";

function Cards() {
  return (
    <div className="home-one-cat edu-service-area service-wrapper-1 edu-section-gap bg-image">
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="section-title text-center"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <span className="pre-title">What is</span>
              <h3 className="title ">ABA Therapy?</h3>
              <p className="description">
                Applied behavior analysis is the science in which the principles
                of the analysis of behavior <br/> are applied systematically to 
                improve socially significant behavior and experimentation <br/> is
                used to identify the variables responsible for behavior change.
              </p>
            </div>
          </div>
        </div>
        <div className="row g-5 mt--25">
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-12"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="service-card service-card-1 radius-small">
              <div className="inner">
                <div className="thumbnail">
                  <img 
                    src="assets/images/category/1.jpg"
                    alt="Service Images"
                  />
                </div>
                <div className="content">
                  <h6 className="title">Evidence-Based</h6>
                  <p className="description">
                    ABA is supported by extensive research and has a strong
                    evidence base. It has been shown to be effective in
                    improving various skills, including communication, social
                    interaction, academic performance, and adaptive behaviors.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-12"
            data-sal-delay="200"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="service-card service-card-1 radius-small">
              <div className="inner">
                <div className="thumbnail">
                  <img
                    src="assets/images/category/2.jpg"
                    alt="Service Images"
                  />
                </div>
                <div className="content">
                  <h6 className="title">Data-Driven Decision Making</h6>
                  <p className="description">
                    ABA relies on systematic data collection and analysis. This
                    data-driven approach helps track progress, identify areas of
                    improvement, and make informed decisions about intervention
                    strategies.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-12"
            data-sal-delay="250"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="service-card service-card-1 radius-small">
              <div className="inner">
                <div className="thumbnail">
                  <img
                    src="assets/images/category/3.jpg"
                    alt="Service Images"
                  />
                </div>
                <div className="content">
                  <h6 className="title">Skill Acquisition</h6>
                  <p className="description">
                    ABA focuses on breaking down complex skills into smaller,
                    manageable steps. This approach facilitates skill
                    acquisition and promotes the development of new abilities by
                    systematically teaching and reinforcing individual
                    components.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-12"
            data-sal-delay="300"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div className="service-card service-card-1 radius-small">
              <div className="inner">
                <div className="thumbnail">
                  <img
                    src="assets/images/category/4.jpg"
                    alt="Service Images"
                  />
                </div>
                <div className="content">
                  <h6 className="title">Behavior Modification</h6>
                  <p className="description">
                    ABA emphasises behavior modification techniques, including
                    positive reinforcement, prompting, fading, and shaping.
                    These strategies help shape desired behaviors and reduce
                    problem behaviors effectively.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1 box-img4">
            <img
              src="assets/images/about/about-02/box4.png"
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-3 box-img3">
            <img
              src="assets/images/about/about-02/box3.png"
              alt="Shape Thumb"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
