import React from "react";

function Module5() {
  return (
    <div className="course-tab-content">
      <h6 className="courses-clr">
        Module 5: Evidence-based Instructional Strategies used in ABA (IBTC-05)
      </h6>
      <p className="description">
        <span className="text-dark fs-2 fw-bold">Course Description: </span>This
        8-hours Training module consists of 9 units. This Training module
        describes, Teaching Protocols, Plans, Scripts, Discrete Trials Therapy,
        Natural Environment Teaching, Verbal Behavior, Task Analyses, Choice and
        Discrimination Learning, Prompting Strategies, Maintenance,
        Generalization teaching strategies to develop and maintain Targeted
        behavior in clients. Participants will be able to understand as well as
        will be able to implement these teaching protocols after completing this
        training Module.
      </p>
      <div className="edu-accordion-02" id="accordionExample1">
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="headingOne">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Unit-1 Teaching Protocols, Plans, Script (IBTC- 5.1)
            </button>
          </div>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                A) Describe Approaches to Instructional Technology
              </h6>
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Personalized
                    System of Instruction (PSI)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Direct
                    Instruction Teaching protocol
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Precision
                    Teaching Method
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Programmed
                    Instruction
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define High-P ,Low-P
                    teaching Strategies
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe SAFMEDS
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">B) Define Lesson plans</h6>
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Characteristics of Effective Lesson Plans{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Components of Lesson Plans{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe comparison of
                    Good Planning vs. Bad Planning
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">C) Define Teaching Scripts</h6>
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Anatomy of a teaching script{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Components of Teaching Scripts{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Steps to write
                    Teaching Scripts
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>How to create one
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading30">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse30"
              aria-expanded="false"
              aria-controls="collapse30"
            >
              Unit-2 Discrete Trial Teaching (IBTC-5.2)
            </button>
          </div>
          <div
            id="collapse30"
            className="accordion-collapse collapse"
            aria-labelledby="heading30"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Discrete trial
                    instruction (DTI)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe Discrete trial
                    instruction (DTI) Developed by
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List 5 Components Of
                    DTT , Give Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Steps to Implement
                    DTT Trials
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe how to
                    increase effectiveness
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <h6 className="courses-clr">A) Define Teaching Scripts</h6>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Random Rotation
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Mass trials
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Block trials
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Expanded trials
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading31">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse31"
              aria-expanded="false"
              aria-controls="collapse31"
            >
              Unit-3 IncidentalTeaching (IBTC-5.3)
            </button>
          </div>
          <div
            id="collapse31"
            className="accordion-collapse collapse"
            aria-labelledby="heading31"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Incidental
                    Teaching
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List another term for
                    incidental teaching /Natural Environment Teaching (NET)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Examples of
                    Incidental Teaching
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List the basic steps of
                    Incidental Teaching
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Benefits of Natural
                    Environment Teaching
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Capturing,
                    Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Contriving,
                    Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading32">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse32"
              aria-expanded="false"
              aria-controls="collapse32"
            >
              Unit-4 Teaching Verbal Behavior (IBTC-5.4)
            </button>
          </div>
          <div
            id="collapse32"
            className="accordion-collapse collapse"
            aria-labelledby="heading32"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define History and
                    Skinner’s Verbal Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Concept of
                    functional Language
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Elementary Verbal
                    Operant’s
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Mand ,Provide
                    Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Tact , Provide
                    Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Intraverbal,
                    Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Formal
                    Similarity, Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Point to Point
                    Correspondence, Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Duplic Verbal
                    operants ,Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Codic Verbal
                    Operant ,Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Applications of
                    Verbal Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Role of Verbal
                    Behavior in Language Assessment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Role of Verbal
                    Behavior in Language Intervention
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Mand Training
                    and Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Echoic Training
                    and Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Intraverbal
                    Training and Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading33">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse33"
              aria-expanded="false"
              aria-controls="collapse33"
            >
              Unit-5 Task Analysis (IBTC- 5.5)
            </button>
          </div>
          <div
            id="collapse33"
            className="accordion-collapse collapse"
            aria-labelledby="heading33"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Task Analysis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Constructing and
                    Validating a Task Analysis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe Importance of
                    Task Analysis
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Task Analysis of Bed
                    making Skills
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading34">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse34"
              aria-expanded="false"
              aria-controls="collapse34"
            >
              Unit-6 Choice and Discrimination Learning (IBTC- 5.6)
            </button>
          </div>
          <div
            id="collapse34"
            className="accordion-collapse collapse"
            aria-labelledby="heading34"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Discriminative
                    Stimulus SD
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Stimulus Delta
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Stimulus Control
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Development of
                    Stimulus Control A.K.A Stimulus Discrimination Training
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Examples of
                    Discrimination Training
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List 2 types of
                    Discrimination Trainings
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Simultaneous
                    Discrimination Training
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Successive
                    Discrimination Training
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Application of
                    Discrimination Training
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Describe effect of
                    Discrimination Training on Choice Making
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading35">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse35"
              aria-expanded="false"
              aria-controls="collapse35"
            >
              Unit-7 Prompting Strategies (IBTC- 5.7)
            </button>
          </div>
          <div
            id="collapse35"
            className="accordion-collapse collapse"
            aria-labelledby="heading35"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">A) Define What is Prompt</h6>
                    <h6 className="courses-clr">
                      B) Provide Examples vs. Non-Examples
                    </h6>
                    <h6 className="courses-clr">C) Define Types of Prompts</h6>
                    <h6 className="courses-clr">D) Define Response prompts</h6>
                    <i className="icon-Double-arrow"></i>List Types of Response
                    Prompts
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Verbal
                    Instruction (Vocal verbal vs Non Vocal Verbal)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Modeling
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Physical
                    Guidance
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Transfer of
                    Stimulus control from Response Prompts to natural stimuli
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Response Prompt
                    Fading
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Types of Response
                    Prompt Fading
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Most-to-Least
                    Prompt
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Least-to-Most
                    Prompt
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Graduated
                    Guidance
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Delayed
                    Prompting
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <h6 className="courses-clr">E) Define Stimulus Prompts</h6>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Types of Stimulus
                    Prompts
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Movement Cues
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Positional
                    Prompts Guidance
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Redundancy
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Transfer of
                    Stimulus control from Stimulus Prompts to natural stimuli
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Stimulus Prompt
                    Fading
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Types of Stimulus
                    Prompt Fading
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Positional Cue
                    Fading
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Movement Cue
                    Fading
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Shape
                    Transformations
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading36">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse36"
              aria-expanded="false"
              aria-controls="collapse36"
            >
              Unit-8 Maintenance (IBTC- 5.8)
            </button>
          </div>
          <div
            id="collapse36"
            className="accordion-collapse collapse"
            aria-labelledby="heading36"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Response
                    Maintenance
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Steps for Response
                    Maintenance
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Steps to
                    Maintenance of the learned Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Evaluation
                    Process for Maintenance
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading37">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse37"
              aria-expanded="false"
              aria-controls="collapse37"
            >
              Unit-9 Generalization (IBTC-5.9)
            </button>
          </div>
          <div
            id="collapse37"
            className="accordion-collapse collapse"
            aria-labelledby="heading37"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Generalization
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define
                    Setting/Situation Generalization
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Generalization
                    Across Subjects
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Generalization
                    across Behaviors( Response Generalization)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Generalization
                    across Time ( Response Maintenance)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Stimulus
                    Generalization
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define
                    Overgeneralization
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Steps for
                    Generalization of Behavior
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Module5;
