import React from "react";

function Profile2() {
  return (
    <article className="postcard light blue">
      <div className="postcard__img_link">
        <img
          src="assets/images/instructor/profile/profile1.jpeg"
          className="postcard__img"
          alt="head"
        />
      </div>
      <div className="postcard__text t-dark">
        <h1 className="postcard__title blue">
          <h5> Humaira Shaheen </h5>
        </h1>
        <div className="postcard__subtitle bolder" style={{ color: "#f9b01e" }}>
          Special Educationist/Educational Psychologist
        </div>
        <div className="postcard__bar"></div>
        <div className="postcard__preview-txt">
          Since 2017, Humera has been working at Learning Chip as an educational
          psychologist and special educator. She has two master's degrees in the
          fields of psychology and special needs education, and she has five
          years of experience working as a special education teacher at the
          government school for hearing-impaired individuals in Sahiwal. She is
          currently working with autistic children after completing her 40-hour
          RBT training.
        </div>
      </div>
    </article>
  );
}

export default Profile2;
