import React, { useEffect } from "react";
import Main from "../component/event/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function Event() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Events | Learning Chip</title>
        <meta
          name="description"
          content="Explore upcoming events at Learning Chip. Stay informed about ABA therapy workshops, educational seminars, and community outreach programs for differently-abled children and their families. Join us to engage, learn, and contribute to the inclusive education community."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default Event;
