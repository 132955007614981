import React, { useEffect } from "react";
import Animation from "../component/common/Animation";
import Main from "../component/PrivacyPolicy/Main";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Privacy & Policy | Learning Chip</title>
        <meta
          name="description"
          content="Read the privacy policy of Learning Chip - The first leading ABA therapy and learning center in Sahiwal, Punjab. Learn about how we collect, use, and protect your personal information when you visit our website or use our services."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default PrivacyPolicy;
