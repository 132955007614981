import React from "react";
import CoursesCard from "../courses/CoursesCard";

function OurCourses() {
  return (
    <div className="edu-course-area  bg-color-white">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-12">
            <div
              className="section-title text-center"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <span className="pre-title" style={{marginTop: '50px'}}>ABA</span>
              <h3 className="title">Training Courses</h3>
            </div>
          </div>
        </div>
        <CoursesCard />
      </div>
    </div>
  );
}

export default OurCourses;
