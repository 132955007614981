import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import BreadCrumb from "../common/BreadCrumb";
import OnlineServices from "./OnlineServices";
import OnSiteServices from "./OnSiteServices";

function Main() {
  return (
    <div className="main-wrapper">
      <Header />
      <BreadCrumb title="Services" />
      <OnlineServices/>
      <OnSiteServices/>
      <Footer />
    </div>
  );
}

export default Main;
