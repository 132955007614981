import React from "react";

function Module6() {
  return (
    <div className="course-tab-content">
      <h6 className="courses-clr">
        Module 6: Dealing with Challenging Behaviors (IBTC-06)
      </h6>
      <p className="description">
        <span className="text-dark fs-2 fw-bold">Course Description: </span>This
        6-hours Training module consists of 5 units. This Training module
        describes how to Decrease, eliminate, and replace problem behavior. This
        module covers function-based intervention techniques for addressing
        problem behavior it places a focus on developing alternate behavior
        repertoires with functional communication trainings and with the use of
        other Antecedent Base Intervention Strategies. This module also covers
        the use of differential reinforcement and Extinction procedures to
        develop alternate behaviors and eliminate problematic Behaviors.
        Participants will be able to understand as well as will be able to
        implement these Behavior Modification Strategies after completing this
        training Module.
      </p>
      <div className="edu-accordion-02" id="accordionExample1">
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="headingOne">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Unit-1 Function of Behavior (IBTC- 6.1)
            </button>
          </div>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      A) Define Function of Behavior
                    </h6>
                    <h6 className="courses-clr">
                      B) Define Function vs. Topography
                    </h6>
                    <h6 className="courses-clr">
                      C) List Main 2 Types of Functions of behavior
                    </h6>
                    <h6 className="courses-clr">
                      D) Define Behavior maintained by Positive Reinforcement
                      Sr+
                    </h6>
                    <i className="icon-Double-arrow"></i>List Types of Positive
                    Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Social Positive
                    Reinforcement(Attention)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Tangible
                    Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Automatic
                    Positive Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <h6 className="courses-clr">
                  E) Define Behavior maintained by Negative Reinforcement Sr-
                </h6>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Types of Negative
                    Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Social Negative
                    Reinforcement (Escape)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Negative
                    Automatic Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading45">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse45"
              aria-expanded="false"
              aria-controls="collapse45"
            >
              Unit-2 Antecedent Intervention Strategies (IBTC- 6.2)
            </button>
          </div>
          <div
            id="collapse45"
            className="accordion-collapse collapse"
            aria-labelledby="heading45"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Antecedent
                    Intervention
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Role of Antecedent in
                    Treatment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define effects of
                    abolishing operations in Antecedent Intervention
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Functional
                    Classification of Antecedent Intervention Strategies
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                A) Define Generic (Contingency Independent) Antecedent
                Intervention Strategies
              </h6>
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Examples of Generic (Contingency Independent)
                    Antecedent Intervention Strategies{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      B) Define Function Based (Contingency dependent)
                      Antecedent Intervention Strategies
                    </h6>
                    <i className="icon-Double-arrow"></i>
                    List Examples Function Based (Contingency dependent)
                    Antecedent Intervention Strategies{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      C) Define Non contingent Reinforcement (NCR)
                    </h6>
                    <h6 className="courses-clr">
                      D) Define Non contingent Reinforcement (NCR) with positive
                      Reinforcement
                    </h6>
                    <i className="icon-Double-arrow"></i>List NCR Antecedent
                    Interventions for Behavior maintained by Attention (Sr+)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List NCR Antecedent
                    Interventions for Behavior maintained by Tangible (Sr+)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      E) Define Non contingent Reinforcement (NCR) with Negative
                      Reinforcement( Escape)
                    </h6>
                    <i className="icon-Double-arrow"></i>List NCR Antecedent
                    Interventions for Behavior maintained by Escape (Sr-)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      F) Define NCR with Automatic Reinforcement
                    </h6>
                    <i className="icon-Double-arrow"></i>List NCR Antecedent
                    Interventions for Behavior maintained by Automatic
                    Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Effective use of
                    Non contingent Reinforcement (NCR) Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Advantages of of
                    Non contingent Reinforcement (NCR)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Disadvantages of
                    Non contingent Reinforcement (NCR)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define High-probability
                    Request Sequence (Behavioral Momentum) as Antecedent
                    intervention Strategies
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define guidelines for
                    using Behavioral Momentum
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading46">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse46"
              aria-expanded="false"
              aria-controls="collapse46"
            >
              Unit-3 Differential Reinforcement (IBTC- 6.3)
            </button>
          </div>
          <div
            id="collapse46"
            className="accordion-collapse collapse"
            aria-labelledby="heading46"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <h6 className="courses-clr">
                A) Define Differential Reinforcement
              </h6>
              <h6 className="courses-clr">
                B) Provide Examples of Differential Reinforcement
              </h6>
              <h6 className="courses-clr">
                C) List Types of Differential Reinforcement
              </h6>
              <h6 className="courses-clr">
                D) Define Differential Reinforcement of Alternative Behavior
                (DRA)
              </h6>
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Examples of DRA
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      E) Define Differential Reinforcement for Other Behavior
                      (DRO)
                    </h6>
                    <i className="icon-Double-arrow"></i>Provide examples of DRO
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List General Types of
                    DRO
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Interval DRO
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Fixed Interval
                    DRO (FI-DRO)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Variable
                    Interval DRO (VI-DRO)
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Guidelines For
                    Using DRO
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      F) Define Differential Reinforcement for Incompatible
                      Behavior (DRI)
                    </h6>
                    <i className="icon-Double-arrow"></i>
                    Provide Examples of DRI{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Guidelines for using DRI{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      G) Define Differential Reinforcement of Low Rates of
                      Responding(DRL)
                    </h6>
                    <i className="icon-Double-arrow"></i>Provide examples of DRL
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Types of DRL
                    Procedures
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Full-Session DRL
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Interval DRL
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Spaced
                    Responding DRL or DRL-T
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Guidelines for
                    using DRL
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading47">
            <button
              className="edu-accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse47"
              aria-expanded="false"
              aria-controls="collapse47"
            >
              Unit-4 Functional Communication Training (IBTC- 6.4)
            </button>
          </div>
          <div
            id="collapse47"
            className="accordion-collapse collapse"
            aria-labelledby="heading47"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define Functional Communication Training (FCT){" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Define 3 Stages of FCT{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Guidelines for Developing FCT Interventions{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      A) Define Effective use of FCT
                    </h6>
                    <i className="icon-Double-arrow"></i>
                    Describe Schedule Thinning{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    Describe Decreased Use of Verbal Prompts
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>
                    List Advantages vs. Disadvantages of Functional
                    Communication Training{" "}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="edu-accordion-item">
          <div className="edu-accordion-header" id="heading48">
            <button
              className="edu-accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse48"
              aria-expanded="false"
              aria-controls="collapse48"
            >
              Unit-5 Extinction in Practice (IBTC- 6.5)
            </button>
          </div>
          <div
            id="collapse48"
            className="accordion-collapse collapse"
            aria-labelledby="heading48"
            data-bs-parent="#accordionExample1"
          >
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Extinction
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Procedural vs.
                    Functional form of Extinction
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Define Extinction Procedures
                    </h6>
                    <i className="icon-Double-arrow"></i>Define Extinction of
                    Behavior Maintained by Positive Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Extinction of
                    Behavior Maintained by Negative Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Extinction of
                    Behavior Maintained by Automatic Reinforcement
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Define Possible unwanted Effects of Extinction{" "}
                    </h6>
                    <i className="icon-Double-arrow"></i>Define Gradual Decrease
                    in Frequency
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Extinction
                    Induced aggression
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Behavioral
                    Contrast
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Extinction Burst
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Define Spontaneous
                    Recovery
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
            <div className="edu-accordion-body">
              <ul>
                <li>
                  <div className="text">
                    <h6 className="courses-clr">
                      Unit-5A Positive Punishment (ABATC- 6.5A)
                    </h6>
                    <i className="icon-Double-arrow"></i>Definition of Positive
                    Punishment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Characteristics
                    of Positive Punishment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Examples & Non-Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Operation and Defining
                    Effect of Punishment
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <h6 className="courses-clr">
                  Define Variables Effecting Resistance to Extinction{" "}
                </h6>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>Provide Examples
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <h6 className="courses-clr">
                  Describe How to use Extinction Effectively{" "}
                </h6>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Practical
                    Considerations When Using Extinction
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>List Practical
                    Considerations for minimizing extinction Burst effects
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State Ethical use of
                    Extinction and Clients Rights
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
                <li>
                  <div className="text">
                    <i className="icon-Double-arrow"></i>State when extinction
                    Should not Use
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Module6;
