import React from "react";
import { ImageGallery } from "react-image-grid-gallery";

function CertificationCelebration() {
  const imagesArray = [
    {
      alt: "Image1's alt text",
      caption: "certification celebration",
      src: "assets/images/Website Images/certification celebration/1.jpg",
    },

    {
      alt: "Image2's alt text",
      caption: "certification celebration",
      src: "assets/images/Website Images/certification celebration/2.jpg",
    },
    {
      alt: "Image3's alt text",
      caption: "certification celebration",
      src: "assets/images/Website Images/certification celebration/7.jpg",
    },
    {
      alt: "Image3's alt text",
      caption: "certification celebration",
      src: "assets/images/Website Images/certification celebration/3.jpg",
    },

    {
      alt: "Image3's alt text",
      caption: "certification celebration",
      src: "assets/images/Website Images/certification celebration/5.jpg",
    },
    {
      alt: "Image3's alt text",
      caption: "certification celebration",
      src: "assets/images/Website Images/certification celebration/8.jpg",
    },
  ];
  return (
    <div className=" edu-blog-area edu-section-gap">
      <div className="wrapper">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">Certification</span>
                <h3 className="title mb-5">Celebration</h3>
              </div>
              <div
                className="gallery-grid-wrapper mesonry-list overflow-hidden section-title"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <ImageGallery
                  imagesInfoArray={imagesArray}
                  columnWidth={210}
                  gapSize={20}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CertificationCelebration;
