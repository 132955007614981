import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import BreadCrumb from '../common/BreadCrumb'
import MediaPhotos from './MediaPhotos'

function Main() {
  return (
    <div className='main-wrapper'>
      <Header/>
      <BreadCrumb title="Media"/>
      <MediaPhotos/>
      <Footer/>
    </div>
  )
}

export default Main
