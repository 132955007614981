import React, { useEffect } from "react";
import Main from "../component/ContactUs/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Contact Us | Learning Chip</title>
        <meta
          name="description"
          content="Get in touch with Learning Chip's team to learn more about our ABA therapy and educational support services for differently-abled children. Contact us for inquiries, appointments, or to discuss how we can assist you in providing the best support for your child's development."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default Contact;
