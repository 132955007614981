import React, { useEffect } from "react";
import Main from "../component/AboutUs/Main";
import Animation from "../component/common/Animation";
import { Helmet } from "react-helmet";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>About Us | Learning Chip</title>
        <meta
          name="description"
          content="Discover the story of Learning Chip, the first leading ABA therapy and learning center in Sahiwal, Punjab, dedicated to providing inclusive education and support for differently-abled children."
        />
      </Helmet>
      <Animation />
      <Main />
    </div>
  );
}

export default About;
